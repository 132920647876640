import { NgModule, ErrorHandler, Injector, Injectable } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './sidebar/sidebar.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { RouterModule } from '@angular/router';
import { NgbModule, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';

import { TenantsComponent } from '../pages/tenants/tenants.component';
import { TenantDetailsComponent } from '../pages/tenant-details/tenant-details.component';
import { AccountsComponent } from '../pages/accounts/accounts.component';
import { AccountDetailsComponent } from '../pages/account-details/account-details.component';
import { VehiclesComponent } from '../pages/vehicles/vehicles.component';
import { VehicleDetailsComponent } from '../pages/vehicle-details/vehicle-details.component';
import { CustomersComponent } from '../pages/customers/customers.component';
import { CustomerDetailsComponent } from '../pages/customer-details/customer-details.component';
import { DeliveriesComponent } from '../pages/deliveries/deliveries.component';
import { DeliveryDetailsComponent } from '../pages/delivery-details/delivery-details.component';
import { CUsComponent } from '../pages/cus/cus.component';
import { CUDetailsComponent } from '../pages/cu-details/cu-details.component';
import { ErrorModalComponent } from './error-modal/error-modal.component';
import { LogoutComponent } from '../pages/logout/logout.component';
import { HandsetsComponent } from '../pages/handsets/handsets.component';
import { HandsetDetailsComponent } from '../pages/handset-details/handset-details.component';
import { HandsetsWaitingApprovalComponent } from '../pages/handsets-waiting-approval/handsets-waiting-approval.component';
import { ImpersonateModalComponent } from './impersonate-modal/impersonate-modal.component';
import { NgbdSortableHeader } from '../directives/sortable-directive';
import { MomentModule } from 'ngx-moment';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { DirectUnlockComponent } from './direct-unlock/direct-unlock.component';
import { ToastsContainerComponent } from './toasts-container/toasts-container.component';
import { ToastrModule } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService implements ErrorHandler {
  constructor(private injector: Injector) {}

  handleError(error: any) {
    console.error('Unhandled error:', error);

    /*const overlay: Overlay = this.injector.get(Overlay);
    const overlayRef: OverlayRef = overlay.create();

    let modalService = this.injector.get(NgbModal);
    const modalRef = modalService.open(ErrorModalComponent);
    modalRef.componentInstance.errorMessage = error;*/
  }
}

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    //MaterialModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MomentModule,
    ToastrModule.forRoot()
  ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    TenantsComponent,
    TenantDetailsComponent,
    AccountsComponent,
    AccountDetailsComponent,
    VehiclesComponent,
    VehicleDetailsComponent,
    CustomersComponent,
    CustomerDetailsComponent,
    DeliveryDetailsComponent,
    DeliveriesComponent,
    CUsComponent,
    CUDetailsComponent,
    HandsetsComponent,
    HandsetDetailsComponent,
    HandsetsWaitingApprovalComponent,
    ErrorModalComponent,
    LogoutComponent,
    NgbdSortableHeader,
    //TableDateFormat,
    //MomentModule,
    ImpersonateModalComponent,
    ChangePasswordComponent,
    DirectUnlockComponent,
    ToastsContainerComponent,
  ],
  exports: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    //MaterialModule
    ToastsContainerComponent,
  ],
  //providers: [{provide: ErrorHandler, useClass: ErrorHandlerService}]
})
export class ComponentsModule { }
