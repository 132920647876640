import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';

import { AccountService, AccountsTableDataSource } from 'src/app/services/account.service';
import { NgbdSortableHeader, SortEvent } from 'src/app/directives/sortable-directive';
import { Router } from '@angular/router';

import { Account } from '../../models/account.model';

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss']
})
export class AccountsComponent implements OnInit {
  dataSource: AccountsTableDataSource;

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(private router: Router, private accountService: AccountService) {
    this.dataSource = new AccountsTableDataSource(this.accountService);

    this.dataSource.load();
  }

  ngOnInit() {
  }

  onSort({column, direction}: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.dataSource.sortColumn = column;
    this.dataSource.sortDirection = direction;
  }

  public edit(event, account) {
    //this.router.navigate(['account-details/' + account.id], {state: {account: account}});
    this.router.navigate(['account-details/' + account.id]);
  }

  getStatus(status:string) {
    switch(status) {
      case Account.STATUS_ACTIVE:
        return 'Active';
      case Account.STATUS_DISABLED:
        return 'Disabled';
    }
  }

  getType(type:string) {
    switch(type) {
      case Account.TYPE_ADMIN:
        return 'Admin';
      case Account.TYPE_SUPERVISOR:
        return 'Supervisor';
      case Account.TYPE_NORMAL:
        return 'Normal';
    }
  }
}
