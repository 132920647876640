import { Component, OnInit, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { VehiclesDataSource, VehicleService } from 'src/app/services/vehicle.service';
import { DeliveryService } from 'src/app/services/delivery.service';
import { MyValidators } from 'src/app/services/validators.service';
import { AccountsDataSource, AccountService } from 'src/app/services/account.service';
import { CustomersDataSource, CustomerService } from 'src/app/services/customer.service';
import { Vehicle } from 'src/app/models/vehicle.model';
import { Router, ActivatedRoute } from '@angular/router';
import { Delivery, Delivery_Vehicle, Delivery_Driver, Delivery_Customer } from 'src/app/models/delivery.model';
import { HttpClient } from '@angular/common/http';
import { debounceTime, distinctUntilChanged, switchMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';
import { Observable, Subject, merge } from 'rxjs';
import { GpsLocation } from 'src/app/models/gps_location';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrorModalComponent } from 'src/app/components/error-modal/error-modal.component';
import { SearchInfo } from 'src/app/services/interfaces/search-info.interface';
import { DirectUnlockComponent } from 'src/app/components/direct-unlock/direct-unlock.component';

declare const google: any;

@Component({
  selector: 'app-delivery-details',
  templateUrl: './delivery-details.component.html',
  styleUrls: ['./delivery-details.component.scss']
})
export class DeliveryDetailsComponent implements OnInit {
  deliveryForm: FormGroup;
  accountsDataSource: AccountsDataSource;
  vehiclesDataSource: VehiclesDataSource;
  customersDataSource: CustomersDataSource;

  selectedVehicle: any = null;

  drivers = null;
  vehicles = null;
  customers = null;

  viewState: any = null;
  delivery: Delivery = null;

  canSave = true;
  loading = false;

  map = null;
  marker = null;
  deliveredLocationMarker = null;
  circle = null;

  submitted = false;

  focusVehicle$ = new Subject<string>();
  focusDriver$ = new Subject<string>();
  focusCustomer$ = new Subject<string>();

  constructor(
    private httpClient: HttpClient,
    private formBuilder: FormBuilder,
    private router: Router,
    private readonly route: ActivatedRoute,
    private accountService: AccountService,
    private deliveryService: DeliveryService,
    private vehicleService: VehicleService,
    private customerService: CustomerService,
    private modalService: NgbModal,
    private ngZone: NgZone
    ) {

    if (this.router.getCurrentNavigation().extras && this.router.getCurrentNavigation().extras.state) {
      this.viewState = this.router.getCurrentNavigation().extras.state;
      this.canSave = false;
      this.loading = false;
    } else {
      this.route.paramMap.subscribe(r => {
        let id = r.get("id");

        if (id) {
          this.loading = true;
          let s = new SearchInfo([id]);
          deliveryService.get(s).subscribe(
            (delivery) => {
              this.setData(delivery);
              this.updateMap(this.delivery.gps_location.latitude, this.delivery.gps_location.longitude, 12, this.delivery.radius);
            }
          );
        }
      });
    }

    this.accountsDataSource = new AccountsDataSource(this.accountService);
    this.vehiclesDataSource = new VehiclesDataSource(this.vehicleService);
    this.customersDataSource = new CustomersDataSource(this.customerService);

    this.drivers = (text$: Observable<any>) => {
      const debouncedText$ = text$.pipe(
        debounceTime(300),
        distinctUntilChanged());

      const inputFocus$ = this.focusDriver$;
      return merge(debouncedText$, inputFocus$).pipe(
        switchMap(search =>
          this.accountsDataSource.read(new SearchInfo(null, 10, 0, search)).pipe(
            map((response: any) => {
              return response.items;
            }),
            catchError(() => {
              return of([]);
            })
          )
        )
      );
    }


    this.vehicles = (text$: Observable<any>) => {
      const debouncedText$ = text$.pipe(
        debounceTime(300),
        distinctUntilChanged());

      const inputFocus$ = this.focusVehicle$;

      return merge(debouncedText$, inputFocus$).pipe(
        switchMap(search =>
          this.vehiclesDataSource.read(new SearchInfo(null, 10, 0, search), this.f.driver.value.id).pipe(
            map((response) => {
              return response.items;
            }),
            catchError(() => {
              return of([]);
            })
          )
        )
      );
    }

    this.customers = (text$: Observable<any>) => {
      const debouncedText$ = text$.pipe(
        debounceTime(300),
        distinctUntilChanged());
      const inputFocus$ = this.focusCustomer$;

      return merge(debouncedText$, inputFocus$).pipe(
        switchMap(search =>
          this.customersDataSource.read(new SearchInfo(null, 10, 0, search)).pipe(
            map((response) => {
              return response.items;
            }),
            catchError(() => {
              return of([]);
            })
          )
        )
      );
    }
  }

  get f() { return this.deliveryForm.controls; }

  tasksToWait = 3;
  tasksCompleted = 0;
  ngOnInit() {
    this.deliveryForm = this.formBuilder.group({
      radius: new FormControl('100', [Validators.required, Validators.pattern('[0-9]+')]),
      unlockTime: new FormControl('10', [Validators.required, Validators.pattern('[0-9]+')]),
      driver: new FormControl('', Validators.required, MyValidators.isSelected()),
      vehicle: new FormControl('', Validators.required, MyValidators.isSelected()),
      customer: new FormControl('', Validators.required, MyValidators.isSelected()),
      customerAddress: new FormControl('', Validators.required),
      datetime: new FormControl(new Date(), Validators.required),
      datetime_end: new FormControl(new Date(), Validators.required),
      smartlock_BL: new FormControl(false),
      smartlock_BR: new FormControl(false),
      smartlock_FL: new FormControl(false),
      smartlock_FR: new FormControl(false),
      smartlock_R: new FormControl(false),
    });

    if (this.viewState) {
      this.setData(this.viewState.delivery);
    }

    this.initMap();
  }

  onSelect($ev){
    this.selectedVehicle = $ev.item
  }

  hasSmartLock(position: "R" | 'BL' | 'BR' | 'FL' | 'FR' ) {
    if (!this.selectedVehicle){
      return false;
    }
    return this.selectedVehicle.smartlocks.some(e => e.position == position);
  }

  setData(delivery: Delivery) {
    this.tasksCompleted = 0;

    this.delivery = delivery;

    this.selectedVehicle = delivery.vehicle;

    this.f.radius.setValue(this.delivery.radius);
    this.f.unlockTime.setValue(this.delivery.unlock_time);
/* 
    this.delivery.vehicle.smartlocks.forEach(function (s) {
      switch (s.position) {
        case 'BL':
          this.f.smartlock_BL.setValue(true);
          break;
        case 'BR':
          this.f.smartlock_BR.setValue(true);
          break;
        case 'FL':
          this.f.smartlock_FL.setValue(true);
          break;
        case 'FR':
          this.f.smartlock_FR.setValue(true);
          break;
        case 'R':
          this.f.smartlock_R.setValue(true);
          break;
      }

    }.bind(this)); */

    this.delivery.smartlocks_positions.forEach(function (s) {      
      switch (s) {
        case 'BL':
          this.f.smartlock_BL.setValue(true);
          break;
        case 'BR':
          this.f.smartlock_BR.setValue(true);
          break;
        case 'FL':
          this.f.smartlock_FL.setValue(true);
          break;
        case 'FR':
          this.f.smartlock_FR.setValue(true);
          break;
        case 'R':
          this.f.smartlock_R.setValue(true);
          break;
      } 
    }.bind(this));

    if (this.delivery.data.location)
      this.f.customerAddress.setValue(this.delivery.data.location);

    this.accountsDataSource.read(new SearchInfo([this.delivery.driver_id])).toPromise()
      .then((res) => {
        this.f.driver.setValue(res);

        if (++this.tasksCompleted == this.tasksToWait) this.canSave = true;
      });

    this.vehiclesDataSource.read(new SearchInfo([this.delivery.vehicle_id])).toPromise()
      .then((res) => {
        this.f.vehicle.setValue(res);

        if (++this.tasksCompleted == this.tasksToWait) this.canSave = true;
      });

    this.customersDataSource.read(new SearchInfo([this.delivery.customer_id])).toPromise()
      .then((res) => {
        this.f.customer.setValue(res);

        if (++this.tasksCompleted == this.tasksToWait) this.canSave = true;
      });

    this.f.datetime.setValue(this.delivery.datetime);
    this.f.datetime_end.setValue(this.delivery.datetime_end);

    this.canSave = true;
    this.loading = false;

    if (delivery.status == Delivery.STATUS_COMPLETED) {
      /*this.f.driver.disable();
      this.f.vehicle.disable();
      this.f.datetime.disable();
      this.f.customer.disable();
      this.f.location.disable();
      this.f.radius.disable();*/

      const controls = this.deliveryForm.controls;
      for (const name in controls) {
        controls[name].disable();
      }
    }
  }

  validate() {
    if ( !this.f.smartlock_BL && this.f.smartlock_BR && this.f.smartlock_FL && this.f.smartlock_FR && !this.f.smartlock_BL.value && !this.f.smartlock_BR.value && !this.f.smartlock_R.value && !this.f.smartlock_FL.value &&  !this.f.smartlock_FB.value) {
      //this.openErrorModal('Select at least one smartlock');
      this.f.smartlock_BL.setErrors({ 'error': 'no smarlock checked' });
      this.f.smartlock_BR.setErrors({ 'error': 'no smarlock checked' });
      this.f.smartlock_FL.setErrors({ 'error': 'no smarlock checked' });
      this.f.smartlock_FR.setErrors({ 'error': 'no smarlock checked' });
      this.f.smartlock_R.setErrors({ 'error': 'no smarlock checked' });
    } else {
      this.f.smartlock_BL.setErrors(null);
      this.f.smartlock_BR.setErrors(null);
      this.f.smartlock_FL.setErrors(null);
      this.f.smartlock_FR.setErrors(null);
      this.f.smartlock_R.setErrors(null);
    }

    if (this.deliveryForm.invalid) {
      //this.invalidControls = this.findInvalidControls();
      return;
    }

    return true;
  }

  async onSubmit() {
    
    this.loading = true;
    this.submitted = true;

    if (!this.validate()) {
      this.loading = false;
      return;
    }

    if (!this.delivery)
      this.delivery = new Delivery();

    //this.delivery.unlock_codes = [];

    this.delivery.radius = parseInt(this.f.radius.value);
    this.delivery.unlock_time = parseInt(this.f.unlockTime.value);
    if (!this.delivery.status)
      this.delivery.status = Delivery.STATUS_READY;
      this.delivery.driver_id = this.f.driver.value.id;
      this.delivery.datetime = this.f.datetime.value;
      this.delivery.datetime_end = this.f.datetime_end.value;
      this.delivery.vehicle_id = this.f.vehicle.value.id;
      this.delivery.customer_id = this.f.customer.value.id;
      this.delivery.gps_location = new GpsLocation(
      this.f.customer.value.gps_location.latitude,
      this.f.customer.value.gps_location.longitude);

    this.delivery.data = {
      title: this.f.customer.value.name,
      location: this.f.customerAddress.value,
      gps_location: new GpsLocation(this.f.customer.value.gps_location.latitude, this.f.customer.value.gps_location.longitude),
    };

    this.delivery.encrypted_data = null;

    // Delivery_Vehicle
    if (!this.delivery.vehicle)
      this.delivery.vehicle = new Delivery_Vehicle();

    this.delivery.vehicle.id = this.f.vehicle.value.id;
    this.delivery.vehicle.name = this.f.vehicle.value.name;

    //this.delivery.vehicle.smartlocks = [];


    this.delivery.smartlocks_positions = [];

    
    if (this.f.smartlock_BL.value) {
      this.delivery.smartlocks_positions.push('BL');
    }

    if (this.f.smartlock_BR.value) {
      this.delivery.smartlocks_positions.push('BR');
    }

    if (this.f.smartlock_FL.value) {
      this.delivery.smartlocks_positions.push('FL');
    }

    if (this.f.smartlock_FR.value) {
      this.delivery.smartlocks_positions.push('FR');
    }

    if (this.f.smartlock_R.value) {
      this.delivery.smartlocks_positions.push('R');
    }


   /*  if (this.f.smartlock_BL.value) {
      this.delivery.vehicle.smartlocks.push({
        id: '-',
        position: 'BL',
      });
    }

    if (this.f.smartlock_BR.value) {
      this.delivery.vehicle.smartlocks.push({
        id: '-',
        position: 'BR',
      });
    }

    if (this.f.smartlock_FL.value) {
      this.delivery.vehicle.smartlocks.push({
        id: '-',
        position: 'FL',
      });
    }

    if (this.f.smartlock_FR.value) {
      this.delivery.vehicle.smartlocks.push({
        id: '-',
        position: 'FR',
      });
    }

    if (this.f.smartlock_R.value) {
      this.delivery.vehicle.smartlocks.push({
        id: '-',
        position: 'R',
      });
    }
 */
    // Delivery_Driver
    if (!this.delivery.driver)
      this.delivery.driver = new Delivery_Driver();

    this.delivery.driver.id = this.f.driver.value.id;
    this.delivery.driver.name = this.f.driver.value.name;

    // Delivery_Customer
    if (!this.delivery.customer)
      this.delivery.customer = new Delivery_Customer();

    this.delivery.customer.id = this.f.customer.value.id;
    this.delivery.customer.name = this.f.customer.value.name;

    let promise = null;
    if (this.delivery.id && this.delivery.id !== '_auto_') {
      promise = this.deliveryService.update(this.delivery).toPromise();
    } else {
      this.delivery.id = '_auto_';
      promise = this.deliveryService.add(this.delivery).toPromise();
      let self = this;
      promise.then((res) => {
        self.delivery.id = res.id;
      });
    }

    promise
      .then((r) => { this.loading = false; })
      .catch((err) => {
        this.loading = false;
        console.log('Error:', err);
        this.openErrorModal(err.error);
      });

      console.log(this.delivery);
      
  }

  driversAutocompleteResultFormat(value: any) {
    return value.name;
  }

  driversAutocompleteListItemFormat(value: any) {
    if (value.name)
      return value.name
    return value;
  }

  vehiclesAutocompleteResultFormat(value: any) {
    return value.name;
  }

  vehiclesAutocompleteListItemFormat(value: any) {
    if (value.name)
      return value.name
    return value;
  }

  customersAutocompleteResultFormat(value: any) {
    return value.name;
  }

  customersAutocompleteListItemFormat(value: any) {
    if (value.name)
      return value.name;
    return value;
  }

  customersSelectionChanged(e) {
    this.f.customerAddress.setValue(e.item.address);

    var location = new google.maps.LatLng(e.item.gps_location.latitude, e.item.gps_location.longitude);
    this.setMarker(location, e.item.name, this.f.radius.value);
  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.deliveryForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }

    return invalid;
  }

  initMap(lat = null, lng = null, zoom = 16) {
    let map_canvas = document.getElementById('map-canvas');

    if (!lat || !lng) {
      lat = 41.873604;
      lng = 12.463417;
      zoom = 5;
    }

    let location = null;
    if (this.delivery) {
      lat = this.delivery.gps_location.latitude;
      lng = this.delivery.gps_location.longitude;
    }

    location = new google.maps.LatLng(lat, lng);
    var mapOptions = {
      zoom: zoom,
      scrollwheel: true,
      center: location,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      styles: [
        { "featureType": "administrative", "elementType": "labels.text.fill", "stylers": [{ "color": "#444444" }] },
        { "featureType": "landscape", "elementType": "all", "stylers": [{ "color": "#f2f2f2" }] },
        { "featureType": "poi", "elementType": "all", "stylers": [{ "visibility": "off" }] },
        { "featureType": "road", "elementType": "all", "stylers": [{ "saturation": -100 }, { "lightness": 45 }] },
        { "featureType": "road.highway", "elementType": "all", "stylers": [{ "visibility": "simplified" }] },
        { "featureType": "road.arterial", "elementType": "labels.icon", "stylers": [{ "visibility": "off" }] },
        { "featureType": "transit", "elementType": "all", "stylers": [{ "visibility": "off" }] },
        { "featureType": "water", "elementType": "all", "stylers": [{ "color": '#5e72e4' }, { "visibility": "on" }] }
      ]
    }

    this.map = new google.maps.Map(map_canvas, mapOptions);

    var infowindow = new google.maps.InfoWindow({
      content: ''
    });

    /*
    google.maps.event.addListener(marker, 'click', function() {
        infowindow.open(map, marker);
    });
    */

    if (this.delivery)
      this.setMarker(location, this.delivery.customer.name, this.f.radius.value);

    if (this.delivery && this.delivery.delivered_gps_location) {
      var deliveredLocation = new google.maps.LatLng(this.delivery.delivered_gps_location.latitude, this.delivery.delivered_gps_location.longitude);
      this.setDeliveredLocationMarker(deliveredLocation, this.delivery.customer.name);
    }
  }

  updateMap(lat = null, lng = null, zoom = 12, circleRadius = null) {
    var myLatlng = new google.maps.LatLng(lat, lng);

    this.map.setCenter(myLatlng);
    this.map.setZoom(zoom);

    this.setMarker(myLatlng, lat + ' ' + lng, circleRadius);

    if (this.delivery && this.delivery.delivered_gps_location) {
      var deliveredLocation = new google.maps.LatLng(this.delivery.delivered_gps_location.latitude, this.delivery.delivered_gps_location.longitude);
      this.setDeliveredLocationMarker(deliveredLocation, this.delivery.customer.name);
    }
  }

  private setMarker(location, title, circleRadius) {
    if (typeof circleRadius === 'string')
      circleRadius = parseFloat(circleRadius);

    this.map.setCenter(location);
    this.map.setZoom(16);

    var infowindow = new google.maps.InfoWindow({
      content: '<b>' + title + '</b>',
      size: new google.maps.Size(150, 50)
    });

    if (!this.marker) {
      this.marker = new google.maps.Marker({
        position: location,
        map: this.map,
        title: title,
      });
      google.maps.event.addListener(this.marker, 'click', function () {
        infowindow.open(this.map, this.marker);
      });
    } else {
      this.marker.setPosition(location);
      this.marker.setTitle(title);
    }

    this.circle = new google.maps.Circle({
      strokeColor: '#00FF00',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#00FF00',
      fillOpacity: 0.35,
      map: this.map,
      center: location,
      radius: circleRadius
    });
  }

  private setDeliveredLocationMarker(location, title) {
    var infowindow = new google.maps.InfoWindow({
      content: '<b>' + title + '</b>',
      size: new google.maps.Size(150, 50)
    });

    if (!this.deliveredLocationMarker) {
      this.deliveredLocationMarker = new google.maps.Marker({
        position: location,
        map: this.map,
        title: "Delivered position",
        icon: {
          url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png"
        }
      });
      google.maps.event.addListener(this.deliveredLocationMarker, 'click', function () {
        infowindow.open(this.map, this.deliveredLocationMarker);
      });
    } else {
      this.deliveredLocationMarker.setPosition(location);
      this.deliveredLocationMarker.setTitle(title);
    }
  }

  unlock(delivery_id) {
    const modalRef = this.modalService.open(DirectUnlockComponent);
    modalRef.componentInstance.delivery_id = delivery_id;
  }

  openErrorModal(msg: string) {
    this.ngZone.run(() => {
      const modalRef = this.modalService.open(ErrorModalComponent);
      modalRef.componentInstance.errorMessage = msg;
    });
  }
}
