import { GpsLocation } from "./gps_location";

export class CU {
    static STATUS_ACTIVE = 'A';
    static STATUS_DISABLED = 'D';

    static STATUS_LIST = ['A', 'D'];

    public id:string = null;
    public code:string = null;
    public description:string = null;
    public status:string = null;
    public gps_location:GpsLocation = null;
    public tenant_id:string = null;
    public tenant_name:string = null;
    public vendor_tenant_id:string = null;
    public vendor_tenant_name:string = null;
}