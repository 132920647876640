import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  test: Date = new Date();

  version: String = '';

  constructor() { 
    this.version = environment.version + "(" + (environment.production ? "p" : "d") + ")";
  }

  ngOnInit() {
  }

}
