import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { SortEvent, NgbdSortableHeader } from 'src/app/directives/sortable-directive';
import { Router } from '@angular/router';
import { CUsTableDataSource, CUService } from 'src/app/services/cu.service';

@Component({
  selector: 'app-cus',
  templateUrl: './cus.component.html',
  styleUrls: ['./cus.component.css']
})
export class CUsComponent implements OnInit {
  dataSource: CUsTableDataSource;

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(private router: Router, private cuService: CUService) {
    this.dataSource = new CUsTableDataSource(this.cuService);

    this.dataSource.load();
  }

  ngOnInit() {
  }

  onSort({column, direction}: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.dataSource.sortColumn = column;
    this.dataSource.sortDirection = direction;
  }

  public edit(event, cu) {
    this.router.navigate(['cu-details/' + cu.id], {state: {cu: cu}});
  }
}
