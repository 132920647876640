import { Component, OnInit, ViewChild, NgZone } from '@angular/core';
import { Account } from 'src/app/models/account.model';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { ErrorModalComponent } from 'src/app/components/error-modal/error-modal.component';
import { AccountService } from 'src/app/services/account.service';
import { GRANTS, SPECIAL_GRANTS, AuthService } from 'src/app/services/auth.service';
import { SearchInfo } from 'src/app/services/interfaces/search-info.interface';
import { Observable, Subject, merge, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, map, catchError, filter } from 'rxjs/operators';
import { VehiclesDataSource, VehicleService } from 'src/app/services/vehicle.service';
import { HandsetsDataSource, HandsetService } from 'src/app/services/handset.service';
import { TenantsDataSource, TenantService } from 'src/app/services/tenant.service';

@Component({
	selector: 'app-account-details',
	templateUrl: './account-details.component.html',
	styleUrls: ['./account-details.component.css']
})
export class AccountDetailsComponent implements OnInit {
	accountForm: FormGroup;
	canSave = true;
	loading = false;
	account: Account = null;
	viewState: any = null;

	submitted = false;
	new_account = true;

	all_grants = GRANTS;
	grants = {};

	tenantsDataSource: TenantsDataSource;
	tenants = null;
	focusTenants$ = new Subject<string>();
	
	vehiclesDataSource: VehiclesDataSource;
	vehicles = null;
	focusVehicles$ = new Subject<string>();

	handsetsDataSource: HandsetsDataSource;
	handsets = null;
	focusHandsets$ = new Subject<string>();

	triggerButtonSelectAllGrants = true;

	constructor(private router: Router, private readonly route: ActivatedRoute, private formBuilder: FormBuilder, private modalService: NgbModal,
		public authService: AuthService, private accountService: AccountService, private ngZone: NgZone,
		private vehicleService: VehicleService,
		private handsetService: HandsetService,
		private tenantService: TenantService) {

		this.account = new Account();

		if (this.router.getCurrentNavigation().extras && this.router.getCurrentNavigation().extras.state) {
			this.viewState = this.router.getCurrentNavigation().extras.state;
			this.canSave = false;
			this.loading = false;
			this.new_account = false;
		} else {
			this.route.paramMap.subscribe(r => {
				let id = r.get("id");

				if (id) {
					this.loading = true;
					this.new_account = false;
					let s = new SearchInfo([id]);
					accountService.get(s).subscribe(
						(account) => {
							this.setData(account);
						}
					);
				}
			});
		}

		//this.all_grants = GRANTS;

		for (let g in this.all_grants) {
			let group = this.all_grants[g].group;
			if (!this.grants[group])
				this.grants[group] = {};

			this.grants[group][g] = {
				'title': this.all_grants[g].title,
			}
		}

		this.tenantsDataSource = new TenantsDataSource(this.tenantService);
		this.tenants = (text$: Observable<string>) => {
			const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
			const inputFocus$ = this.focusTenants$;
			
			return merge(debouncedText$, inputFocus$).pipe(
				switchMap(search => this.tenantsDataSource.read(new SearchInfo(null, 10, 0, search)).pipe(
					map((response) => {
						return response.items;
					}),
					catchError(() => {
						return of([]);
					})
				))
			)

			// return text$.pipe(
			// 	debounceTime(300),
			// 	distinctUntilChanged(),
			// 	switchMap(search =>
			// 		this.tenantsDataSource.read(new SearchInfo(null, 10, 0, search)).pipe(
			// 			map((response) => {
			// 				return response.items;
			// 			}),
			// 			catchError(() => {
			// 				return of([]);
			// 			})
			// 		)
			// 	)
			// );
		}

		this.handsetsDataSource = new HandsetsDataSource(this.handsetService);
		this.handsets = (text$: Observable<string>) => {
			const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
			const inputFocus$ = this.focusHandsets$;
			
			return merge(debouncedText$, inputFocus$).pipe(
				switchMap(search => this.handsetsDataSource.read(new SearchInfo(null, 10, 0, search)).pipe(
					map((response) => {
						return response.items;
					}),
					catchError(() => {
						return of([]);
					})
				))
			)
			
			// return text$.pipe(
			// 	debounceTime(300),
			// 	distinctUntilChanged(),
			// 	switchMap(search =>
			// 		this.handsetsDataSource.read(new SearchInfo(null, 10, 0, search)).pipe(
			// 			map((response) => {
			// 				if (this.authService.getUser().type !== Account.TYPE_NORMAL) {
			// 					let v = new Handset();
			// 					v.name = '*';
			// 					response.items.unshift(v);
			// 				}

			// 				return response.items;
			// 			}),
			// 			catchError(() => {
			// 				return of([]);
			// 			})
			// 		)
			// 	)
			// );
		}

		this.vehiclesDataSource = new VehiclesDataSource(this.vehicleService);
		this.vehicles = (text$: Observable<string>) => {
			const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
			const inputFocus$ = this.focusVehicles$;
			
			return merge(debouncedText$, inputFocus$).pipe(
				switchMap(search => this.vehiclesDataSource.read(new SearchInfo(null, 10, 0, search)).pipe(
					map((response) => {
						return response.items;
					}),
					catchError(() => {
						return of([]);
					})
				))
			)

			// text$.pipe(
			// 	debounceTime(300),
			// 	distinctUntilChanged(),
			// 	switchMap(search =>
			// 		this.vehiclesDataSource.read(new SearchInfo(null, 10, 0, search)).pipe(
			// 			map((response) => {
			// 				if (this.authService.getUser().type !== Account.TYPE_NORMAL) {
			// 					let v = new Vehicle();
			// 					v.name = '*';
			// 					response.items.unshift(v);
			// 				}

			// 				return response.items;
			// 			}),
			// 			catchError(() => {
			// 				return of([]);
			// 			})
			// 		)
			// 	)
			// );
		}
	}

	get f() { return this.accountForm.controls; }

	tasksToWait = 1;
	tasksCompleted = 0;
	
	ngOnInit(): void {
		let formFields = {
			username: new FormControl('', Validators.required),
			name: new FormControl('', Validators.required),
			password: new FormControl(''),
			email: new FormControl('',  [Validators.required, Validators.email]),
			password_repeat: new FormControl(''),
			tenant: new FormControl(''),
			type: new FormControl(Account.TYPE_NORMAL, Validators.required),
			status: new FormControl(Account.STATUS_ACTIVE, Validators.required),
			handsets_id: new FormControl(''),
			number_unlock_codes: new FormControl(5, [Validators.min(1), Validators.max(5)])
			//vehicles_id: new FormControl(''),
		};

		for (let group_name in this.grants) {
			for (let g in this.grants[group_name]) {
				formFields['grant_' + g] = new FormControl(false);
			}
		}

		this.accountForm = this.formBuilder.group(formFields);
		if (this.viewState && this.viewState.account) {
			this.setData(this.viewState.account);this.tenants
		}
	}

	setData(account: Account) {
		this.tasksCompleted = 0;

		this.account = account;

		this.f.username.setValue(this.account.username);
		this.f.username.disable();
		this.f.name.setValue(this.account.name);
		this.f.email.setValue(this.account.email);
		this.f.password.setValue('');
		this.f.password_repeat.setValue('');
		//this.f.tenant_id.setValue(this.account.tenant_id);
		this.f.type.setValue(this.account.type);
		this.f.status.setValue(this.account.status);
		this.f.number_unlock_codes.setValue(this.account.number_unlock_codes);
		//this.f.grants.setValue(this.account.grants.join(','));
		//this.f.handsets_id.setValue(this.account.handsets_id.join(','));
		//this.f.vehicles_id.setValue(this.account.vehicles_id.join(','));

		this.f.tenant.disable();

		this.tenantsDataSource.read(new SearchInfo([this.account.tenant_id])).toPromise()
			.then((res) => {
				this.f.tenant.setValue(res);

				if (++this.tasksCompleted == this.tasksToWait) this.canSave = true;
			});

		this.account.grants.forEach(function (g) {
			if (g.includes('*')) {
				for (let group_name in this.grants) {
					for (let gl in this.grants[group_name]) {
						let regex = new RegExp(g.replace('.', '\\.').replace('*', '.*'));
						if (regex.test(gl)) {
							this.f['grant_' + gl].setValue(true);
						}
					}
				}
			} else {
				this.f['grant_' + g].setValue(true);
			}
		}.bind(this));

		this.loading = false;
	}

	async onSubmit() {
		this.loading = true;
		this.submitted = true;

		if (!this.account || this.f.password.value || this.f.password_repeat.value) {
			if (this.f.password.value != this.f.password_repeat.value) {
				//this.openErrorModal('Select at least one smartlock');
				this.f.password_repeat.setErrors({ 'error': 'password mismatch' });
			} else {
				this.f.password_repeat.setErrors(null);
			}
		}

		if (!this.validate()) {
			this.loading = false;
			return;
		}

		this.account.username = this.f.username.value;
		this.account.email = this.f.email.value;
		this.account.name = this.f.name.value;
		this.account.password = this.f.password.value ? this.f.password.value : undefined;
		this.account.tenant_id = this.f.tenant.value.id;
		this.account.type = this.f.type.value;
		this.account.status = this.f.status.value;
		this.account.handsets_id = this.f.handsets_id.value.trim() ? this.f.handsets_id.value.split(',') : [];
		this.account.number_unlock_codes = this.f.number_unlock_codes.value;

		if (!this.account.handsets.find(v => v.name === '*'))
			this.account.handsets_id = this.account.handsets.map(v => v.id);
		else
			this.account.handsets_id = [];

		if (!this.account.vehicles.find(v => v.name === '*'))
			this.account.vehicles_id = this.account.vehicles.map(v => v.id);
		else
			this.account.vehicles_id = [];

		let checked_grants = [];
		for (let group_name in this.grants) {
			for (let g in this.grants[group_name]) {
				if (this.f['grant_' + g].value)
					checked_grants.push(g);
			}
		}

		this.account.grants = checked_grants;

		let promise = null;
		if (this.account.id && this.account.id !== '_auto_') {
			promise = this.accountService.update(this.account).toPromise();
		} else {
			//this.account.id = '_auto_';
			promise = this.accountService.add(this.account).toPromise();
			let self = this;
			promise.then((res) => {
				self.account.id = res.id;
			});
		}

		promise
			.then((r) => {
				this.loading = false;
				this.new_account = false;
				this.f.password.setValue('');
				this.f.password_repeat.setValue('');
			})
			.catch((err) => {
				this.loading = false;
				console.log('Error:', err);
				this.openErrorModal(err.error);
			});
	}

	validate() {
		if (this.accountForm.invalid) {
			return;
		}

		return true;
	}

	public findInvalidControls() {
		const invalid = [];
		const controls = this.accountForm.controls;
		for (const name in controls) {
			if (controls[name].invalid) {
				invalid.push(name);
			}
		}

		return invalid;
	}

	tenantsAutocompleteResultFormat(value: any) {
		return value.name;
	}

	tenantsAutocompleteListItemFormat(value: any) {
		if (value.name)
			return value.name
		return value;
	}

	handsetsAutocompleteResultFormat(value: any) {
		return value.name;
	}

	handsetsAutocompleteListItemFormat(value: any) {
		return '';
	}

	handsetsAutocompleteSelectedItem(event) {
		if (event.item.name === '*') {
			this.account.handsets = [event.item];

		} else if (!this.account.handsets ||
			(!this.account.handsets.find(v => v.name === '*') && !this.account.handsets.find(v => v.id === event.item.id))) {
			if (!this.account.handsets)
				this.account.handsets = [];

			this.account.handsets.push(event.item);
		}
	}

	removeHandset(event, id) {
		this.account.handsets = this.account.handsets.filter(h => h.id != id);
	}

	vehiclesAutocompleteResultFormat(value: any) {
		return value.name;
	}

	vehiclesAutocompleteListItemFormat(value: any) {
		return '';
	}

	vehiclesAutocompleteSelectedItem(event) {
		if (event.item.name === '*') {
			this.account.vehicles = [event.item];

		} else if (!this.account.vehicles ||
			(!this.account.vehicles.find(v => v.name === '*') && !this.account.vehicles.find(v => v.id === event.item.id))) {
			if (!this.account.vehicles)
				this.account.vehicles = [];

			this.account.vehicles.push(event.item);
		}
	}

	removeVehicle(event, id) {
		this.account.vehicles = this.account.vehicles.filter(v => v.id != id);
	}

	openErrorModal(msg: string) {
		this.ngZone.run(() => {
			const modalRef = this.modalService.open(ErrorModalComponent);
			modalRef.componentInstance.errorMessage = msg;
		});
	}

	triggerAllCheckBox() {
		Object.keys(this.accountForm.controls).forEach(key => {
			if(key.includes('grant_')) {
				this.accountForm.controls[key].setValue(this.triggerButtonSelectAllGrants);
			}
		});

		this.triggerButtonSelectAllGrants = !this.triggerButtonSelectAllGrants;
	}
}
