import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { TenantService, TenantsTableDataSource } from 'src/app/services/tenant.service';
import { NgbdSortableHeader, SortEvent } from 'src/app/directives/sortable-directive';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tenants',
  templateUrl: './tenants.component.html',
  styleUrls: ['./tenants.component.css']
})
export class TenantsComponent implements OnInit {
  dataSource: TenantsTableDataSource;

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(private router: Router, private tenantService: TenantService) {
    this.dataSource = new TenantsTableDataSource(this.tenantService);

    this.dataSource.load();
  }

  ngOnInit() {
  }

  onSort({column, direction}: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.dataSource.sortColumn = column;
    this.dataSource.sortDirection = direction;
  }

  public edit(event, tenant) {
    this.router.navigate(['tenant-details/' + tenant.id], {state: {tenant: tenant}});
  }

  public getTypeName(type:string) {
    switch(type) {
      case 'F':
        return 'Full';
        break;
      case 'L':
        return 'Light'
        break;
      default:
        return '';
    }
  }

  public getStatusName(type:string) {
    switch(type) {
      case 'A':
        return 'Active';
        break;
      case 'D':
        return 'Disabled'
        break;
      default:
        return '';
    }
  }
}
