import { Component, Input, ViewChild, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'error-modal.component',
  templateUrl: './error-modal.component.html'
})
export class ErrorModalComponent implements OnInit {
  // @ViewChild('modal') modal: any;

  errorMessage: string;

  constructor(private modalService: NgbModal, public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    // console.log('Init error modal')
  }

  // open() {
  //   const modalRef = this.modalService.open(this.modal);
  //   modalRef.componentInstance.errorMessage = 'World';
  // }
}
