import { Component, OnInit } from '@angular/core';
import { DeliveryService } from 'src/app/services/delivery.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'app-direct-unlock',
  templateUrl: './direct-unlock.component.html',
  styleUrls: ['./direct-unlock.component.css']
})
export class DirectUnlockComponent implements OnInit {

  delivery_id:string = null;

  constructor(private activeModal: NgbActiveModal, private deliveryService:DeliveryService, public toastService: ToastService) { }

  ngOnInit(): void {
  }

  async direct_unlock(smartlock_position:string) {
    try {
      let response = await this.deliveryService.direct_unlock(this.delivery_id, smartlock_position).toPromise();

      if(response.success) {
        this.activeModal.close();
        this.toastService.show('The smart-lock will be opened soon', {
          classname: 'bg-success text-white',
          delay: 4000 ,
          autohide: true,
          headertext: 'Smart-lock release'
        });
      } else {
        this.activeModal.close();
        this.toastService.show('Unable to open smart-lock', {
          classname: 'bg-danger text-white',
          delay: 4000 ,
          autohide: true,
          headertext: 'Smart-lock release'
        });
      }

    } catch(e) {
      this.activeModal.close();
      this.toastService.show('Unable to open smart-lock', {
        classname: 'bg-danger text-white',
        delay: 4000 ,
        autohide: true,
        headertext: 'Smart-lock release'
      });
    }
  }
}
