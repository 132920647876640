import { GpsLocation } from "./gps_location";

export class Handset {
    static STATUS_NEW = "N";
    static STATUS_WAITING_APPROVAL = "WA";
    static STATUS_ACTIVE = "A";
    static STATUS_DISABLED = "D";

    static STATUS_LIST = ['N', 'WA', 'A', 'D'];

    public id:string = null;
    public name:string = null;
    public tenant_id:string = null;
    public gps_location:GpsLocation = null;
    public status:string = null;
    public encryption_key:string = null;
    public version:string = null;
    public messages:Array<ServerMessage> = [];
    public app_config = {};

    isNotApproved() {
        return this.status == Handset.STATUS_WAITING_APPROVAL;
    }
}

export abstract class ServerMessage {
    public type:string = null;
}