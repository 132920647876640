import { Vehicle } from "./vehicle.model";
import { Handset } from "./handset.model";

export class Account {
    static STATUS_ACTIVE = 'A';
    static STATUS_DISABLED = 'D';
    static STATUS_LIST = ['A', 'D'];

    static TYPE_ADMIN = 'A';
    static TYPE_SUPERVISOR = 'S';
    static TYPE_NORMAL = 'N';
    static TYPE_LIST = ['A', 'S', 'N'];

    public id:string = null;
    public username:string = null;
    public email:string = null;
    public name:string = null;
    public password:string = null;
    public tenant_id:string = null;
    public type:string = Account.TYPE_NORMAL;
    public status:string = Account.STATUS_ACTIVE;
    public grants:string[] = null;
    public number_unlock_codes: number = 5;
    public handsets_id:string[] = null;
    public vehicles_id:string[] = null;
    public subtenants_id:string[] = null;

    public vehicles:Vehicle[] = [];
    public handsets:Handset[] = [];
}