import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import decode from 'jwt-decode';
import { environment } from 'src/environments/environment';
import { Account } from '../models/account.model';

class User {
    id:string = null;
    username:string = null;
    password:string = null;
    tenant_id:string = null;
    name:string = null;
    type:string = null;
    grants:string[] = [];

    isAdmin() {
        return this.type === Account.TYPE_ADMIN;
    }

    isSuperuser() {
        return this.type === Account.TYPE_SUPERVISOR;
    }

    isNormal() {
        return this.type === Account.TYPE_NORMAL;
    }
}

export let GRANTS = {
    '*': { 'group': 'all', 'title': 'All permissions' },
    'c:handsets.all': { 'group': 'all', 'title': 'Handsets all permissions' },
    'c:vehicles.all': { 'group': 'all', 'title': 'Vehicles all permissions' },
    'c:subtenants.all': { 'group': 'all', 'title': 'Subtenants all permissions'},
    'c:login': { 'group': 'login', 'title': 'Login' },
    'c:impersonate': { 'group': 'login', 'title': 'Impersonate' },
    'c:tenant.get': { 'group': 'tenant', 'title': 'View tenant' },
    'c:tenant.add': { 'group': 'tenant', 'title': 'Add tenant' },
    'c:tenant.update': { 'group': 'tenant', 'title': 'Update tenant' },
    'c:tenant.delete': { 'group': 'tenant', 'title': 'Delete tenant' },
    'c:account.get': { 'group': 'account', 'title': 'View accounts' },
    'c:account.add': { 'group': 'account', 'title': 'Add account' },
    'c:account.update': { 'group': 'account', 'title': 'Update account' },
    'c:account.delete': { 'group': 'account', 'title': 'Delete account' },
    'c:handset.get': { 'group': 'handset', 'title': 'Get handsets' },
    'c:handset.add': { 'group': 'handset', 'title': 'Add handset' },
    'c:handset.update': { 'group': 'handset', 'title': 'Update handset' },
    'c:handset.delete': { 'group': 'handset', 'title': 'Delete handset' },
    'c:delivery.get': { 'group': 'delivery', 'title': 'View deliveries' }, 
    'c:delivery.add': { 'group': 'delivery', 'title': 'Add delivery' },
    'c:delivery.update': { 'group': 'delivery', 'title': 'Update delivery' },
    'c:delivery.delete': { 'group': 'delivery', 'title': 'Delete delivery' },
    'c:vehicle.get': { 'group': 'vehicle', 'title': 'View vehicles' },
    'c:vehicle.add': { 'group': 'vehicle', 'title': 'Add vehicle' },
    'c:vehicle.update': { 'group': 'vehicle', 'title': 'Update vehicle' },
    'c:vehicle.delete': { 'group': 'vehicle', 'title': 'Delete vehicle' },
    'c:customer.get': { 'group': 'customer', 'title': 'View customers' },
    'c:customer.add': { 'group': 'customer', 'title': 'Add customer' },
    'c:customer.update': { 'group': 'customer', 'title': 'Update customer' },
    'c:customer.delete': { 'group': 'customer', 'title': 'Delete customer' },
    'c:cu.get': { 'group': 'cu', 'title': 'View control unit' },
    'c:cu.add': { 'group': 'cu', 'title': 'Add control unit' },
    'c:cu.update': { 'group': 'cu', 'title': 'Update control unit' },
    'c:cu.delete': { 'group': 'cu', 'title': 'Delete control unit' },
    'c:log.get': { 'group': 'log', 'title': 'View logs' },
    'c:handsets_to_approve': { 'group': 'misc', 'title': 'View handsets to approve' },
    'c:activate_handset': { 'group': 'misc', 'title': 'Activate handsets' },
    'c:sync_driver_vehicles': { 'group': 'misc', 'title': 'Sync driver vehicles' },
    'c:handsets_changed_encryption_key': { 'group': 'misc', 'title': 'Update handsets encryption keys' },
    'c:set_unlock_codes': { 'group': 'misc', 'title': 'Update unlock codes' },
    'c:direct_unlock': { 'group': 'misc', 'title': 'Direct unlock' },
    'a:login': { 'group': 'app', 'title': 'App login' },
    'a:register': { 'group': 'app', 'title': 'App register' },
    'a:activate_handset': { 'group': 'app', 'title': 'App handset activation' },
    'a:delivery.get': { 'group': 'app', 'title': 'View deliveries' },
    'a:unlock': { 'group': 'app', 'title': 'Unlock smart-locks' },
    'c:notify_emergency_unlock': { 'group': 'misc', 'title': 'Send email on emergency unlock' }
};

export let SPECIAL_GRANTS = {
    '*': { 'level': 1 },
        'c:*': { 'level': 2 },
            'c:tenant.*': { 'level': 3 },
            'c:account.*': { 'level': 3 },
            'c:handset.*': { 'level': 3 },
            'c:vehicle.*': { 'level': 3 },
            'c:delivery.*': { 'level': 3 },
            'c:customer.*': { 'level': 3 },
            'c:cu.*': { 'level': 3 },
            'c:*.get': { 'level': 3 },
            'c:*.add': { 'level': 3 },
            'c:*.update': { 'level': 3 },
            'c:*.delete': { 'level': 3 },
        'a:*': { 'level': 2 },
};

@Injectable({ providedIn: 'root' })
export class AuthService {
    //private userSubject: BehaviorSubject<User>;
    //public user$: Observable<User>;

    //private tokenSubject: BehaviorSubject<string>;
    //private token$: Observable<string>;

    private token: string;

    user:User;

    constructor(private http: HttpClient, private ngZone: NgZone, private router: Router) {
        this.user = new User();

        let u = JSON.parse(localStorage.getItem('user'));
        if(u) {
            this.user.id = u.id;
            this.user.username = u.username;
            this.user.password = u.password;
            this.user.tenant_id = u.tenant_id;
            this.user.name = u.name;
            this.user.type = u.type;
            this.user.grants = u.grants;
        }

        this.token = localStorage.getItem('token');


        

        //this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        //this.userSubject = new BehaviorSubject<User>(null);
        //this.user$ = this.userSubject.asObservable();
        //this.tokenSubject = new BehaviorSubject<string>(null);
        //this.token$ = this.tokenSubject.asObservable();
    }

    public get userValue(): User {
        //return this.userSubject.value;
        return null;
    }

    public get tokenValue(): string {
        //return this.tokenSubject.value;
        return this.token;
    }

    public getToken() {
        return this.token;
    }

    public setToken(token:string) {
        this.token = token;
    }

    public getUser():User {
        return this.user;
    }

    async login(username:string = null, password:string = null, tenant_id:string = null) {
        username = username || this.user.username;
        password = password || this.user.password;
        tenant_id = tenant_id || this.user.tenant_id;

        // FIXME
        if(password === 'a')
            password = 'Sm4rtL0cks';

        let response = await this.http.post<any>(environment.customer_api_url + `login`, 
        {
            username: username,
            password: password, //'Sm4rtL0cks',
            tenant_id: tenant_id,
        }).toPromise();

        this.token = response.token;

        const tokenPayload = decode(this.token);

        this.user.id = tokenPayload.uid;
        this.user.username = username;
        this.user.password = password;
        this.user.name = tokenPayload.uname;
        this.user.tenant_id = tenant_id;
        this.user.type = tokenPayload.atype;
        let grants:string[] = tokenPayload.g.split(',');

        let all_grants = Object.keys(GRANTS);
        grants.forEach((token_grant) => {
            if(token_grant.includes('*')) {
                all_grants.forEach((g) => {
                    let regex = new RegExp('^' + token_grant.replace('.', '\\.').replace('*', '.*') + '$');
                    if(regex.test(g)) {
                        this.user.grants.push(g);
                    }
                });
            } else {
                this.user.grants.push(token_grant);
            }
        });

        localStorage.setItem('user', JSON.stringify(this.user));
        localStorage.setItem('token', this.token);

        

        //this.tokenSubject.next(response.token);
    }

    async impersonate(tenant_id:string, username:string) {   
        let response = await this.http.post<any>(environment.customer_api_url + `impersonate`,
            {
                tenant_id: tenant_id,
                username: username,
            },
        ).toPromise();

        this.token = response.token;

        const tokenPayload = decode(this.token);

        this.user.id = tokenPayload.sub;
        this.user.username = username;
        this.user.password = null;
        this.user.name = tokenPayload.uname;
        this.user.tenant_id = tenant_id;
        this.user.type = tokenPayload.atype;
        this.user.grants = tokenPayload.g.split(',');
        let grants:string[] = tokenPayload.g.split(',');

        let all_grants = Object.keys(GRANTS);
        grants.forEach((token_grant) => {
            if(token_grant.includes('*')) {
                all_grants.forEach((g) => {
                    let regex = new RegExp('^' + token_grant.replace('.', '\\.').replace('*', '.*') + '$');
                    if(regex.test(g)) {
                        this.user.grants.push(g);
                    }
                });
            } else {
                this.user.grants.push(token_grant);
            }
        });


        localStorage.setItem('user', JSON.stringify(this.user));
        localStorage.setItem('token', this.token);
    }

    public async checkToken(): Promise<boolean> {
        if (this.token) {
            const tokenPayload = decode(this.token);

            const expireAt = new Date(0);
            expireAt.setUTCSeconds(tokenPayload.exp);
            if(expireAt >= new Date(Date.now() + 30000)) {
                return true;
            } else {
                try {
                    if(!this.user || !this.user.password) {
                        return false;
                    }

                    await this.login();
                    return true;

                } catch(e) {
                    console.error('Login error: ', e);
                }
            }
        } else { 
            // Token not found, go to login page
        }

        return false;
    }

    logout() {
        //localStorage.removeItem('currentUser');
        //this.currentUserSubject.next(null);

        this.token = null;
        this.user = null;

        localStorage.removeItem('user');
        localStorage.removeItem('token');
    }
}