import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject, of } from 'rxjs';
import { retry, catchError, finalize, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CollectionViewer, DataSource } from "@angular/cdk/collections";

import { AuthService } from './auth.service';
import { CU } from 'src/app/models/cu.model';
import { PagedResult } from '../models/paged_result.model';
import { SearchInfo, SearchInfoDeliveries } from './interfaces/search-info.interface';
import { TableDataSource } from './table-datasource';
import { Service } from './base';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class CUService extends Service {
    constructor(protected router: Router, private http: HttpClient, protected authService:AuthService) {
        super(router, authService);
    }

    public get(data:SearchInfoDeliveries): Observable<any> {
        if(data.ids && data.ids.length === 1) {
            return this.http.get<PagedResult<CU>>(
                environment.customer_api_url + 'cu/' + data.ids[0],
                {}
            );
        }

        let params:any = {};
        if(data.search)
            params.search = data.search;

        if (data.codes){
            params.codes = JSON.stringify(data.codes);
        }
        return this.http.get<any>(
            environment.customer_api_url + `cus`,
            {
                params: params,
            }
        );
    }

    public add(cu:CU): Observable<any> {
        const options = {
            responseType: 'text' as 'json',
        };

        return this.http.post<string>(environment.customer_api_url + `cu`, cu, options)
            .pipe(map((res) => { return JSON.parse(res); }));
    }

    public update(cu:CU): Observable<any> {
        const options = {
            responseType: 'text' as 'json',
        };

        return this.http.put<string>(environment.customer_api_url + 'cu/' + cu.id, cu, options);
    }

    public directUnlock(smartlock_id: string) {
        return this.http.post<any>(`${environment.customer_api_url}direct_unlock`, { smartlock_id: smartlock_id });
    }
}

export class CUsDataSource {
    public loading = false;

    constructor(private cuService: CUService) {}

    read(data:SearchInfoDeliveries): Observable<any> {
        this.loading = true;

        return this.cuService.get(data).pipe(
            catchError((err) => {
              return throwError(err);
            }),
            finalize(() => this.loading = false)
        );
    }    
}

export class CUsTableDataSource extends TableDataSource<CU> {
    constructor(private cuService: CUService) {
        super(cuService);
    }
}