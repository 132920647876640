import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { HandsetsTableDataSource, HandsetService, HandsetsToApproveTableDataSource } from 'src/app/services/handset.service';
import { NgbdSortableHeader, SortEvent } from 'src/app/directives/sortable-directive';
import { Router } from '@angular/router';

@Component({
  selector: 'app-handsets-waiting-approval',
  templateUrl: './handsets-waiting-approval.component.html',
  styleUrls: ['./handsets-waiting-approval.component.css']
})
export class HandsetsWaitingApprovalComponent implements OnInit {
  dataSource: HandsetsToApproveTableDataSource;

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(private router: Router, private handsetService: HandsetService) {
    this.dataSource = new HandsetsToApproveTableDataSource(this.handsetService);

    this.dataSource.load();
  }

  ngOnInit() {
  }

  onSort({column, direction}: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.dataSource.sortColumn = column;
    this.dataSource.sortDirection = direction;
  }

  public edit(event, handset) {
    this.router.navigate(['handset-details/' + handset.id], {state: {handset: handset}});
  }

  public getStatusName(type:string) {
    switch(type) {
      case 'WA':
        return 'Waiting approval';
      case 'A':
        return 'Active'
      case 'D':
        return 'Disabled'
      default:
        return '';
    }
  }
}
