import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { CustomersTableDataSource, CustomerService } from 'src/app/services/customer.service';
import { NgbdSortableHeader, SortEvent } from 'src/app/directives/sortable-directive';
import { Router } from '@angular/router';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.css']
})
export class CustomersComponent implements OnInit {
  dataSource: CustomersTableDataSource;

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(private router: Router, private customerService: CustomerService) {
    this.dataSource = new CustomersTableDataSource(this.customerService);

    this.dataSource.load();
  }

  ngOnInit() {
  }

  onSort({column, direction}: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.dataSource.sortColumn = column;
    this.dataSource.sortDirection = direction;
  }

  public edit(event, customer) {
    this.router.navigate(['customer-details/' + customer.id], {state: {customer: customer}});
  }
}
