import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Vehicle } from 'src/app/models/vehicle.model';
import { VehicleService } from 'src/app/services/vehicle.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrorModalComponent } from 'src/app/components/error-modal/error-modal.component';
import { Router, ActivatedRoute } from '@angular/router';
import { SearchInfo, SearchInfoDeliveries } from 'src/app/services/interfaces/search-info.interface';
import { Smartlock } from 'src/app/models/smartlock.model';
import { CUService } from 'src/app/services/cu.service';
import { ToastrService } from 'ngx-toastr';
import { ToastService } from 'src/app/services/toast.service';

declare const google: any;

@Component({
  selector: 'app-vehicle-details',
  templateUrl: './vehicle-details.component.html',
  styleUrls: ['./vehicle-details.component.css']
})
export class VehicleDetailsComponent implements OnInit {
  vehicleForm: FormGroup;
  canSave = true;
  loading = false;
  vehicle: Vehicle = null;
  viewState: any = null;

  dynamicArray: Array<Smartlock> = [];
  newDynamic: Smartlock;

  submitted = false;

  smartlocksPositions: Array<any> = [
    { position: 'R', disabled: false },
    { position: 'FL', disabled: false },
    { position: 'FR', disabled: false },
    { position: 'BR', disabled: false },
    { position: 'BL', disabled: false }];

  map: any;

  constructor(
    private router: Router,
    private readonly route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private vehicleService: VehicleService,
    private cuService: CUService,
    private ref: ChangeDetectorRef,
    private ngZone: NgZone,
    public toastService: ToastService
  ) {
    if (this.router.getCurrentNavigation().extras && this.router.getCurrentNavigation().extras.state) {
      this.viewState = this.router.getCurrentNavigation().extras.state;
      this.canSave = false;
      this.loading = false;
    } else {
      this.route.paramMap.subscribe(r => {
        let id = r.get("id");

        if (id) {
          this.loading = true;
          let s = new SearchInfo([id]);
          vehicleService.get(s).subscribe(
            (vehicle) => {
              this.setData(vehicle);
            }
          );
        }
      });
    }
  }

  get f() { return this.vehicleForm.controls; }

  ngOnInit(): void {

    this.newDynamic = { cu_code: '', position: '', hasError: false };
    this.dynamicArray.push(this.newDynamic);

    this.vehicleForm = this.formBuilder.group({
      description: new FormControl('', Validators.required),
      //control_unit_id: new FormControl([]),
      status: new FormControl(Vehicle.STATUS_ACTIVE, Validators.required),
      smartlocks: new FormControl([], Validators.required),
      number_unlock_codes: new FormControl(20, [Validators.required, Validators.min(1), Validators.max(20)])
    });

    if (this.viewState) {
      this.setData(this.viewState.vehicle);
    }
  }

  setData(vehicle: Vehicle) {
    this.vehicle = vehicle;

    this.f.description.setValue(this.vehicle.name);
    this.f.smartlocks.setValue(this.vehicle.smartlocks);
    this.f.status.setValue(this.vehicle.status);
    this.f.number_unlock_codes.setValue(this.vehicle.numberUnlockCodes);

    this.dynamicArray = this.vehicle.smartlocks;
    this.canSave = true;
    this.loading = false;

    this.initMap();
  }

  async onSubmit() {
    this.loading = true;
    this.submitted = true;


    if (!this.dynamicArray || !this.dynamicArray[0].cu_code) {
      this.dynamicArray[0].hasError = true;
      this.loading = false;
      return;
    }

    this.dynamicArray.map(function (obj) { obj.cu_code = obj.cu_code.toString() });
    this.vehicleForm.controls['smartlocks'].setValue(this.dynamicArray);
    let cu_codes = this.dynamicArray.map(function (obj) { return obj.cu_code.toString(); });
  
    let validated = await this.validateCUCodes(cu_codes);

    if(!validated){
      this.loading = false;
      this.ref.detectChanges();
      return;
    }
  
    if (!this.validate()) {
      this.loading = false;
      return;
    }


    if (!this.vehicle)
      this.vehicle = new Vehicle();

    this.vehicle.name = this.f.description.value;
    this.vehicle.status = this.f.status.value;
    this.vehicle.smartlocks = this.dynamicArray;
    this.vehicle.cu_ids = cu_codes;
    this.vehicle.numberUnlockCodes = this.f.number_unlock_codes.value;

    if (this.vehicle.id && this.vehicle.id !== '_auto_') {
      this.vehicleService.update(this.vehicle).subscribe({
        next: res => {
          this.loading = false; 
          this.ref.detectChanges();
        },
        error: err => {
          this.loading = false;                
          if (err.status === 452){
            this.openErrorModal("Duplicate CU code");
          } else {
            this.openErrorModal(err.error);
          }
        }
      })
    } else {
      this.vehicleService.add(this.vehicle).subscribe({
        next: res => {
          this.vehicle.id = res.id;
          this.loading = false;
          this.ref.detectChanges();
        },
        error: err => {
          this.loading = false;                
          if (err.status === 452){
            this.openErrorModal("Duplicate CU code");
          } else {
            this.openErrorModal(err.error);
          }
        }
      })
    }

    /*
    let promise = null;

    if (this.vehicle.id && this.vehicle.id !== '_auto_') {
      promise = this.vehicleService.update(this.vehicle).toPromise();

    } else {
      this.vehicle.id = '_auto_';
      promise = this.vehicleService.add(this.vehicle).toPromise();
      let self = this;

      promise.then((res) => {
        self.vehicle.id = res.id;
        this.loading = false;
        this.ref.detectChanges();
      });
    }

    promise
      .then((r) => { 
        this.loading = false; 
        this.ref.detectChanges();
      })
      .catch((err) => {
        console.log('oooooo');
        
        this.loading = false;                
        if (err.status === 452){
          this.openErrorModal("Duplicate CU code");
        } else {
          this.openErrorModal(err.error);
        }
      }); 
    */
  }

  validate() {
    if (this.vehicleForm.invalid) {
      return;
    }
    return true;
  }

  findElementInArray(array, key, value) {
    for (let i = 0; i < array.length; i++) {
      if (array[i][key] === value) {
        return array[i];
      }
    }
    return null; // Element not found
  }

  hasDuplicates(arr) {
    return arr.some( function(item) {
        return arr.indexOf(item) !== arr.lastIndexOf(item);
    });
}


  validateCUCodes(CUCodes: Array<string>): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      // if (this.hasDuplicates(CUCodes)){     
      //   resolve(false);
      // }

      let hasErrors: boolean = false;
      let s = new SearchInfoDeliveries();
      s.codes = CUCodes;
      
      this.cuService.get(s).subscribe(
        (cus) => {
          this.dynamicArray.forEach(el => {
      
            el.hasError = false;
            let res = this.findElementInArray(cus.items, 'code', el.cu_code);

            if (!res) {
              hasErrors = true;
              el.hasError = true;
            } else {
              el.cu_id = res.id;
            }
          })

          if(hasErrors){
            resolve(false);
          }
          resolve(true)
        });  
    })
  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.vehicleForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }

    return invalid;
  }

  openErrorModal(msg: string) {    
    this.ngZone.run(() => {
      const modalRef = this.modalService.open(ErrorModalComponent);
      modalRef.componentInstance.errorMessage = msg;
    });

    this.ref.detectChanges()
  }


  addRow() {
    this.newDynamic = { cu_id: '', position: '', hasError: false };
    this.dynamicArray.push(this.newDynamic);
    this.updatePositions();
    return true;
  }

  deleteRow(index) {
    if (this.dynamicArray.length == 1) {
      this.updatePositions();
      return false;
    } else {
      this.dynamicArray.splice(index, 1);
      this.updatePositions();
      return true;
    }
  }

  updatePositions() {

    this.smartlocksPositions.map(sp => sp.disabled = false);
    this.smartlocksPositions.forEach(sp => {
      this.dynamicArray.forEach(el => {
        if (el.position == sp.position) {
          sp.disabled = true;
        }
      })
    })
  }

  unlockSmartlock(smartlock_id: string) {
    if(smartlock_id) {
      this.cuService.directUnlock(smartlock_id).subscribe(res => {
        if(res.success) {
          this.toastService.show('Smartlock successfully opened!', {
            classname: 'bg-success text-white',
            delay: 4000 ,
            autohide: true,
            headertext: 'Smart-lock release'
          });
        } else {
          this.openErrorModal('Smartlock direct unlock error')
        }
      })
    } else {
      this.openErrorModal('Smartlock direct unlock error')
    }
  }

  initMap(zoom = 15) {
    let map_canvas = document.getElementById('map-canvas');

    if(this.vehicle && this.vehicle.lastPositions && this.vehicle.lastPositions.length > 0) {
      const path = new google.maps.Polyline({
        path: this.vehicle.lastPositions.map(v => new google.maps.LatLng(v.lat, v.lon)),
        geodesic: true,
        strokeColor: "#FF0000",
        strokeOpacity: 1.0,
        strokeWeight: 2,
      })
  
      let location = new google.maps.LatLng(this.vehicle.lastPositions[0].lat, this.vehicle.lastPositions[0].lon);
      var mapOptions = {
        zoom: zoom,
        scrollwheel: true,
        center: location,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        styles: [
          { "featureType": "administrative", "elementType": "labels.text.fill", "stylers": [{ "color": "#444444" }] },
          { "featureType": "landscape", "elementType": "all", "stylers": [{ "color": "#f2f2f2" }] },
          { "featureType": "poi", "elementType": "all", "stylers": [{ "visibility": "off" }] },
          { "featureType": "road", "elementType": "all", "stylers": [{ "saturation": -100 }, { "lightness": 45 }] },
          { "featureType": "road.highway", "elementType": "all", "stylers": [{ "visibility": "simplified" }] },
          { "featureType": "road.arterial", "elementType": "labels.icon", "stylers": [{ "visibility": "off" }] },
          { "featureType": "transit", "elementType": "all", "stylers": [{ "visibility": "off" }] },
          { "featureType": "water", "elementType": "all", "stylers": [{ "color": '#5e72e4' }, { "visibility": "on" }] }
        ]
      }
  
      this.map = new google.maps.Map(map_canvas, mapOptions);
      path.setMap(this.map);
    }
  }
}
