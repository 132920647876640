import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
import { Observable, throwError, BehaviorSubject, Subject, of } from 'rxjs';
import { catchError, finalize, tap, debounceTime, switchMap, delay, map } from 'rxjs/operators';
import { Delivery } from '../models/delivery.model';
import { /*SortColumn,*/ SortDirection } from '../directives/sortable-directive';
import { ResultPageInfo, PagedResult } from '../models/paged_result.model';
import { SearchInfo } from './interfaces/search-info.interface';
import { TableDataSource } from './table-datasource';
import { Service } from './base';
import { Router } from '@angular/router';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Injectable({ providedIn: 'root' })
export class DeliveryService extends Service {
    constructor(protected router: Router, private http: HttpClient, protected authService:AuthService) {
        super(router, authService);
    }

    public get(data:SearchInfo, args = null): Observable<any> {
        if(data.ids && data.ids.length === 1) {
            return this.http.get<PagedResult<Delivery>>(
                environment.customer_api_url + 'delivery/' + data.ids[0],
                {}
            );
        }

        let params:any = {};
        if(data.search)
            params.search = data.search;

        if(args) {
            let start = args.date;

            if(start) {
                if(moment.isMoment(start))
                    start = start.toDate();
            } else {
                start = new Date();
            }

            params['from_datetime'] = start.toISOString();
            params['to_datetime'] = moment(start).add(1, 'days').toDate().toISOString();
        }

        if(!data.sortColumn)
            data.sortColumn = 'datetime';
        if(!data.sortDirection)
            data.sortDirection = 'desc';

        let sort = [];
        sort.push({ [data.sortColumn]: data.sortDirection });

        params['sort'] = JSON.stringify(sort);

        return this.http.get<any>(
            environment.customer_api_url + `deliveries`,
            {
                params: params,
            },
        );
    }

    public add(delivery:Delivery): Observable<any> {
        const options = {
            responseType: 'text' as 'json',
        };

        return this.http.post<string>(environment.customer_api_url + `delivery`, delivery, options)
            .pipe(map((res) => { return JSON.parse(res); }));
    }

    public update(delivery:Delivery): Observable<any> {
        const options = {
            responseType: 'text' as 'json',
        };

        return this.http.put<string>(environment.customer_api_url + 'delivery/' + delivery.id, delivery, options);
    }

    public direct_unlock(delivery_id:string, smartlock_position:string): Observable<any> {
        const options = {
            responseType: 'text' as 'json',
        };

        return this.http.post<string>(environment.customer_api_url + 'direct_unlock', {
            delivery_id: delivery_id,
            smartlock_position: smartlock_position,
        }, options)
        .pipe(map((res) => { return JSON.parse(res); }));
    }
}

export class DeliveriesDataSource extends TableDataSource<Delivery> {
    set date(date) {
        this.triggerSearch({date: new Date(date.year, date.month-1, date.day)});
    }

    constructor(private deliveryService: DeliveryService) {
        super(deliveryService, { date: moment().hours(0).minutes(0).seconds(0).milliseconds(0) },);
    }
}