import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services';
import { UpdatePassword } from 'src/app/models/update_password.model';
import { AccountService } from 'src/app/services/account.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  changePasswordForm: FormGroup;
  canSave = true;
  loading = false;

  submitted = false;

  error_message = '';
  success_message = '';

  constructor(private router: Router, private formBuilder: FormBuilder, public activeModal: NgbActiveModal, private authService: AuthService, private accountService: AccountService) { }

  ngOnInit(): void {
    this.changePasswordForm = this.formBuilder.group({
      old_password: new FormControl('', Validators.required),
      new_password: new FormControl('', Validators.required),
      repeat_password: new FormControl('', Validators.required),
    });
  }

  get f() { return this.changePasswordForm.controls; }

  async onSubmit() {
    this.error_message = '';
    this.success_message = '';
    this.loading = true;
    this.submitted = true;

    if(!this.validate()) {
      this.loading = false;
      this.error_message = 'Unable to save, check data';
      return;
    }

    let old_password = this.f.old_password.value;
    let new_password = this.f.new_password.value;
    let repeat_password = this.f.repeat_password.value;

    if(new_password != repeat_password) {
      this.loading = false;
      this.error_message = 'Password mismatch';
      return;
    }

    try {
      let update_password = new UpdatePassword();
      update_password.id = this.authService.user.id;
      update_password.old_password = old_password;
      update_password.password = new_password;

      let response = await this.accountService.update(update_password).toPromise();
      this.success_message = 'Password updated';
      this.loading = false;

    } catch(e) {
      this.error_message = e.error;
      this.loading = false;
      console.log('Error: ', e);
    }

    this.loading = false;
  }

  validate() {
    if (this.changePasswordForm.invalid) {
      return;
    }

    return true;
  }

}
