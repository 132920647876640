import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject, of } from 'rxjs';
import { retry, catchError, finalize, debounceTime, distinctUntilChanged, switchMap, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
//import { CollectionViewer, DataSource } from "@angular/cdk/collections";

import { AuthService } from './auth.service';
import { Account } from 'src/app/models/account.model';
import { PagedResult } from '../models/paged_result.model';
import { SearchInfo } from './interfaces/search-info.interface';
import { TableDataSource } from './table-datasource';
import { Service } from './base';
import { Router } from '@angular/router';
import { UpdatePassword } from '../models/update_password.model';

@Injectable({ providedIn: 'root' })
export class AccountService extends Service {
    constructor(protected router: Router, private http: HttpClient, protected authService:AuthService) {
        super(router, authService);
    }

    public get(data:SearchInfo): Observable<any> {
        if(data.ids && data.ids.length === 1) {
            return this.http.get<PagedResult<Account>>(
                environment.customer_api_url + 'account/' + data.ids[0],
                {}
            );
        }

        let params:any = {};
        if(data.search)
            params.search = data.search;

        return this.http.get<PagedResult<Account>>(
            environment.customer_api_url + 'accounts',
            {
                params: params,
            }
        );
    }

    public add(account:Account): Observable<any> {
        const options = {
            responseType: 'text' as 'json',
        };

        return this.http.post<string>(environment.customer_api_url + `account`, account, options)
            .pipe(map((res) => { return JSON.parse(res); }));
    }

    public update(account:Account|UpdatePassword): Observable<any> {
        const options = {
            responseType: 'text' as 'json',
        };

        return this.http.put<string>(environment.customer_api_url + 'account/' + account.id, account, options);
    }
}

export class AccountsDataSource {
    public loading = false;

    constructor(private accountService: AccountService) {}

    read(data:SearchInfo): Observable<any> {
        this.loading = true;

        return this.accountService.get(data).pipe(
            catchError((err) => {
              return throwError(err);
            }),
            finalize(() => this.loading = false)
        );
    }    
}

export class AccountsTableDataSource extends TableDataSource<Account> {
    constructor(private accountService: AccountService) {
        super(accountService);
    }
}