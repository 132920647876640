import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject, of } from 'rxjs';
import { retry, catchError, finalize, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CollectionViewer, DataSource } from "@angular/cdk/collections";

import { AuthService } from './auth.service';
import { Handset } from 'src/app/models/handset.model';
import { PagedResult } from '../models/paged_result.model';
import { SearchInfo } from './interfaces/search-info.interface';
import { TableDataSource } from './table-datasource';
import { Service } from './base';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class HandsetService extends Service {
    constructor(protected router: Router, private http: HttpClient, protected authService:AuthService) {
        super(router, authService);
    }

    public get(data:SearchInfo): Observable<any> {
        if(data.ids && data.ids.length === 1) {
            return this.http.get<PagedResult<Handset>>(
                environment.customer_api_url + 'handset/' + data.ids[0],
                {}
            );
        }

        let params:any = {};
        if(data.search)
            params.search = data.search;

        return this.http.get<any>(
            environment.customer_api_url + `handsets`,
            {
                params: params,
            }
        );
    }

    public add(handset:Handset): Observable<any> {
        const options = {
            responseType: 'text' as 'json',
        };

        return this.http.post<string>(environment.customer_api_url + `handset`, handset, options)
            .pipe(map((res) => { return JSON.parse(res); }));
    }

    public update(handset:Handset): Observable<any> {
        const options = {
            responseType: 'text' as 'json',
        };

        return this.http.put<string>(environment.customer_api_url + 'handset/' + handset.id, handset, options);
    }

    public get_waiting_approval(): Observable<any> {
        return this.http.get<PagedResult<Handset>>(
            environment.customer_api_url + 'handsets_to_approve',
            {}
        );
    }
}

export class HandsetsDataSource {
    public loading = false;

    constructor(private handsetService: HandsetService) {}

    read(data:SearchInfo): Observable<any> {
        this.loading = true;

        return this.handsetService.get(data).pipe(
            catchError((err) => {
              return throwError(err);
            }),
            finalize(() => this.loading = false)
        );
    }    
}

export class HandsetsTableDataSource extends TableDataSource<Handset> {
    constructor(private handsetService: HandsetService) {
        super(handsetService);
    }
}

export class HandsetsToApproveTableDataSource extends TableDataSource<Handset> {
    constructor(private handsetService: HandsetService) {
        super(handsetService);
    }

    protected read(pageSize, page, search, sortColumn, sortDirection): Observable<PagedResult<Handset>> {
        return this.handsetService.get_waiting_approval();
    }
}