import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services';
import { Account } from 'src/app/models/account.model';

declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
    account_types: string[];
    grants_required: string[];
    subItems: RouteInfo[],
}

export const ROUTES: RouteInfo[] = [
    { path: '/dashboard', title: 'Dashboard',  icon: 'fa fa-desktop text-primary', class: '', 
        account_types: [Account.TYPE_ADMIN, Account.TYPE_SUPERVISOR, Account.TYPE_NORMAL],
        grants_required: [],
        subItems: [],
    },
    { path: '/tenants', title: 'Tenants',  icon: 'fa fa-home text-primary', class: '',
        account_types: [Account.TYPE_ADMIN, /* FIXME Account.TYPE_SUPERVISOR */],
        grants_required: ['c:tenant.get'],
        subItems: [
            { path: '/tenants', title: 'Tenants',  icon: 'fa fa-list text-primary', class: '',
            account_types: [Account.TYPE_ADMIN, /* FIXME Account.TYPE_SUPERVISOR */],
            grants_required: ['c:tenant.get'],
                subItems: []
            },{ path: '/tenant-details', title: 'Create tenant',  icon: 'fa fa-plus text-primary', class: '', 
                account_types: [Account.TYPE_ADMIN, /* FIXME Account.TYPE_SUPERVISOR */],
                grants_required: ['c:tenant.add'],
                subItems: []
            }],
    },
    //{ path: '/tenant-details', title: 'Create tenant',  icon: 'fa fa-user-plus text-primary', class: '', 
    //    account_types: [Account.TYPE_ADMIN, /* FIXME Account.TYPE_SUPERVISOR */],
    //    grants_required: ['c:tenant.add'],
    //},
    { path: '/accounts', title: 'Accounts',  icon: 'fa fa-user text-primary', class: '', 
        account_types: [Account.TYPE_ADMIN, Account.TYPE_SUPERVISOR],
        grants_required: ['c:account.get'],
        subItems: [
            { path: '/accounts', title: 'Accounts',  icon: 'fa fa-list text-primary', class: '', 
                account_types: [Account.TYPE_ADMIN, Account.TYPE_SUPERVISOR],
                grants_required: ['c:account.get'],
                subItems: [],
            },{ path: '/account-details', title: 'Create account',  icon: 'fa fa-plus text-primary', class: '', 
                account_types: [Account.TYPE_ADMIN, Account.TYPE_SUPERVISOR],
                grants_required: ['c:account.add'],
                subItems: [],
            }],
    },
    /*{ path: '/account-details', title: 'Create account',  icon: 'fa fa-user-plus text-primary', class: '', 
        account_types: [Account.TYPE_ADMIN, Account.TYPE_SUPERVISOR],
        grants_required: ['c:account.add'],
        subItems: [],
    },*/
    { path: '/vehicles', title: 'Vehicles',  icon: 'fa fa-truck text-primary', class: '', 
        account_types: [Account.TYPE_ADMIN, Account.TYPE_SUPERVISOR, Account.TYPE_NORMAL],
        grants_required: ['c:vehicle.get'],
        subItems: [
            { path: '/vehicles', title: 'Vehicles',  icon: 'fa fa-list text-primary', class: '', 
                account_types: [Account.TYPE_ADMIN, Account.TYPE_SUPERVISOR, Account.TYPE_NORMAL],
                grants_required: ['c:vehicle.get'],
                subItems: [],
            },{ path: '/vehicle-details', title: 'Create vehicle',  icon: 'fa fa-plus text-primary', class: '', 
                account_types: [Account.TYPE_ADMIN, Account.TYPE_SUPERVISOR, Account.TYPE_NORMAL],
                grants_required: ['c:vehicle.add'],
                subItems: [],
            }],
    },
    /*{ path: '/vehicle-details', title: 'Create vehicle',  icon: 'fa fa-truck text-primary', class: '', 
        account_types: [Account.TYPE_ADMIN, Account.TYPE_SUPERVISOR, Account.TYPE_NORMAL],
        grants_required: ['c:vehicle.add'],
        subItems: [],
    },*/
    { path: '/customers', title: 'Customers',  icon: 'fa fa-users text-primary', class: '', 
        account_types: [Account.TYPE_ADMIN, Account.TYPE_SUPERVISOR, Account.TYPE_NORMAL],
        grants_required: ['c:customer.get'],
        subItems: [
            { path: '/customers', title: 'Customers',  icon: 'fa fa-list text-primary', class: '', 
                account_types: [Account.TYPE_ADMIN, Account.TYPE_SUPERVISOR, Account.TYPE_NORMAL],
                grants_required: ['c:customer.get'],
                subItems: [],
            },{ path: '/customer-details', title: 'Create customer',  icon: 'fa fa-plus text-primary', class: '', 
                account_types: [Account.TYPE_ADMIN, Account.TYPE_SUPERVISOR, Account.TYPE_NORMAL],
                grants_required: ['c:customer.add'],
                subItems: [],
            }],
    },
    /*{ path: '/customer-details', title: 'Create customer',  icon: 'fa fa-users text-primary', class: '', 
        account_types: [Account.TYPE_ADMIN, Account.TYPE_SUPERVISOR, Account.TYPE_NORMAL],
        grants_required: ['c:customer.add'],
        subItems: [],
    },*/
    { path: '/deliveries', title: 'Deliveries',  icon: 'fa fa-shopping-bag text-primary', class: '', 
        account_types: [Account.TYPE_ADMIN, Account.TYPE_SUPERVISOR, Account.TYPE_NORMAL],
        grants_required: ['c:delivery.get'],
        subItems: [
            { path: '/deliveries', title: 'Deliveries',  icon: 'fa fa-list text-primary', class: '', 
                account_types: [Account.TYPE_ADMIN, Account.TYPE_SUPERVISOR, Account.TYPE_NORMAL],
                grants_required: ['c:delivery.get'],
                subItems: [],
            },{ path: '/delivery-details', title: 'Create delivery',  icon: 'fa fa-plus text-primary', class: '', 
                account_types: [Account.TYPE_ADMIN, Account.TYPE_SUPERVISOR, Account.TYPE_NORMAL],
                grants_required: ['c:delivery.add'],
                subItems: [],
            }],
    },
    /*{ path: '/delivery-details', title: 'Create delivery',  icon: 'fa fa-shopping-bag text-primary', class: '', 
        account_types: [Account.TYPE_ADMIN, Account.TYPE_SUPERVISOR, Account.TYPE_NORMAL],
        grants_required: ['c:delivery.add'],
        subItems: [],
    },*/
    { path: '/cus', title: 'CUs',  icon: 'fa fa-lock text-primary', class: '', 
        account_types: [Account.TYPE_ADMIN, /* FIXME Account.TYPE_SUPERVISOR */],
        grants_required: ['c:cu.get'],
        subItems: [
            { path: '/cus', title: 'CUs',  icon: 'fa fa-list text-primary', class: '', 
                account_types: [Account.TYPE_ADMIN, /* FIXME Account.TYPE_SUPERVISOR */],
                grants_required: ['c:cu.get'],
                subItems: [],
            },{ path: '/cu-details', title: 'Create CU',  icon: 'fa fa-plus text-primary', class: '', 
                account_types: [Account.TYPE_ADMIN],
                grants_required: ['c:cu.add'],
                subItems: [],
            }],
    },
    /*{ path: '/cu-details', title: 'Create CU',  icon: 'fa fa-shopping-bag text-primary', class: '', 
        account_types: [Account.TYPE_ADMIN],
        grants_required: ['c:cu.add'],
        subItems: [],
    },*/
    { path: '/handsets', title: 'Handsets',  icon: 'fa fa-mobile text-primary', class: '', 
        account_types: [Account.TYPE_ADMIN, Account.TYPE_SUPERVISOR],
        grants_required: ['c:handset.get'],
        subItems: [],
    },
    { path: '/handsets-waiting-approval', title: 'Handsets waiting approval',  icon: 'fa fa-mobile text-primary', class: '', 
        account_types: [Account.TYPE_ADMIN, Account.TYPE_SUPERVISOR],
        grants_required: ['c:handsets_to_approve'],
        subItems: [],
    },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  public menuItems: RouteInfo[];
  public isCollapsed = true;

  constructor(private router: Router, private authService: AuthService) { }

  ngOnInit() {
    let user = this.authService.getUser();

    this.menuItems = ROUTES.filter((menuItem) => {
        if(!menuItem.account_types.includes(user.type))
            return false;
        
        for(let i=0; i<menuItem.grants_required.length; i++) {
            let g = menuItem.grants_required[i];
            if(!user.grants.includes(g))
                return false;
        }

        return true;
    });
    
    this.router.events.subscribe((event) => {
      this.isCollapsed = true;
   });
  }

  add() {
  }
}
