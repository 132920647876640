import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-impersonate-modal',
  templateUrl: './impersonate-modal.component.html',
  styleUrls: ['./impersonate-modal.component.css']
})
export class ImpersonateModalComponent implements OnInit {
  impersonateForm: FormGroup;
  canSave = true;
  loading = false;

  submitted = false;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder, 
    private authService: AuthService,
    private ngZone:NgZone,

    ) { }

  ngOnInit(): void {
    this.impersonateForm = this.formBuilder.group({
      tenant_id: new FormControl('', Validators.required),
      account_id: new FormControl('', Validators.required),
    });
  }

  get f() { return this.impersonateForm.controls; }

  async onSubmit() {
    this.loading = true;
    this.submitted = true;

    if(!this.validate()) {
      this.loading = false;
      return;
    }

    let tenant_id = this.f.tenant_id.value.toString();
    let account_id = this.f.account_id.value;
    

    try {
      await this.authService.impersonate(tenant_id, account_id);
      //this.router.navigate(['/dashboard']);
      let self = this;

      this.ngZone.run(() => {
        self.router.navigate(['/dashboard']);
        window.location.reload(); 
      });
  
    } catch(e) {
      console.log('Error: ', e);
    }

    this.loading = false;
  }

  validate() {
    if (this.impersonateForm.invalid) {
      return;
    }

    return true;
  }

}
