import { Component, OnInit, NgZone } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Handset } from 'src/app/models/handset.model';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HandsetService } from 'src/app/services/handset.service';
import { SearchInfo } from 'src/app/services/interfaces/search-info.interface';
import { ErrorModalComponent } from 'src/app/components/error-modal/error-modal.component';

declare const google: any;

@Component({
  selector: 'app-handset-details',
  templateUrl: './handset-details.component.html',
  styleUrls: ['./handset-details.component.css']
})
export class HandsetDetailsComponent implements OnInit {
  handsetForm: FormGroup;
  canSave = true;
  loading = false;
  handset:Handset = null;
  viewState:any = null;

  submitted = false;

  map = null;
  marker = null;

  constructor(private ngZone: NgZone, private router: Router, private readonly route: ActivatedRoute, private formBuilder: FormBuilder, private modalService: NgbModal, private handsetService: HandsetService) {
    if(this.router.getCurrentNavigation().extras && this.router.getCurrentNavigation().extras.state) {
      this.viewState = this.router.getCurrentNavigation().extras.state;
      this.canSave = false;
      this.loading = false;
    } else {
      this.route.paramMap.subscribe(r => {
        let id = r.get("id");

        if(id) {
          this.loading = true;
          let s = new SearchInfo([id]);
          handsetService.get(s).subscribe(
            (handset) => {
              this.setData(handset);
              this.updateMap(this.handset.gps_location.latitude, this.handset.gps_location.longitude, 12);
            }
          );
        }
      });  
    }
  }

  get f() { return this.handsetForm.controls; }

  ngOnInit(): void {
    this.handsetForm = this.formBuilder.group({
      name: new FormControl('', Validators.required),
      status: new FormControl('', Validators.required),
    });

    if(this.viewState) {
      this.setData(this.viewState.handset);
    }

    this.initMap();
  }

  setData(handset:Handset) {
    this.handset = handset;

    this.f.name.setValue(this.handset.name);
    this.f.status.setValue(this.handset.status);

    this.canSave = true;
    this.loading = false;
  }

  async onSubmit() {
    this.loading = true;
    this.submitted = true;

    if(!this.validate()) {
      this.loading = false;
      return;
    }

    if(!this.handset)
      this.handset = new Handset();

    this.handset.name = this.f.name.value;
    this.handset.status = this.f.status.value;

    let promise = this.handsetService.update(this.handset).toPromise();

    promise
      .then((r) => { this.loading = false; })
      .catch((err) => { 
        this.loading = false;
        console.log('Error:', err);
        this.openErrorModal(err.error);
      });
  }

  validate() {
    if (this.handsetForm.invalid) {
      return;
    }

    return true;
  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.handsetForm.controls;
    for (const name in controls) {
        if (controls[name].invalid) {
            invalid.push(name);
        }
    }

    return invalid;
  }

  initMap(lat = null, lng = null, zoom=16) {
    let map_canvas = document.getElementById('map-canvas');

    if(!lat || !lng) {
      lat = 41.873604;
      lng = 12.463417;
      zoom = 5;
    }

    let location = null;
    if(this.handset) {
      lat = this.handset.gps_location.latitude;
      lng = this.handset.gps_location.longitude;
    }

    location = new google.maps.LatLng(lat, lng);
    var mapOptions = {
        zoom: zoom,
        scrollwheel: true,
        center: location,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        styles: [
          {"featureType":"administrative","elementType":"labels.text.fill","stylers":[{"color":"#444444"}]},
          {"featureType":"landscape","elementType":"all","stylers":[{"color":"#f2f2f2"}]},
          {"featureType":"poi","elementType":"all","stylers":[{"visibility":"off"}]},
          {"featureType":"road","elementType":"all","stylers":[{"saturation":-100},{"lightness":45}]},
          {"featureType":"road.highway","elementType":"all","stylers":[{"visibility":"simplified"}]},
          {"featureType":"road.arterial","elementType":"labels.icon","stylers":[{"visibility":"off"}]},
          {"featureType":"transit","elementType":"all","stylers":[{"visibility":"off"}]},
          {"featureType":"water","elementType":"all","stylers":[{"color":'#5e72e4'},{"visibility":"on"}]}
        ]
    }

    this.map = new google.maps.Map(map_canvas, mapOptions);

    var infowindow = new google.maps.InfoWindow({
        content: ''
    });

    /*
    google.maps.event.addListener(marker, 'click', function() {
        infowindow.open(map, marker);
    });
    */

    if(this.handset)
      this.setMarker(location, '');
  }

  updateMap(lat = null, lng = null, zoom=12) {
    var myLatlng = new google.maps.LatLng(lat, lng);

    this.map.setCenter(myLatlng);
    this.map.setZoom(zoom);

    this.setMarker(myLatlng, lat + ' ' + lng);
  }

  private setMarker(location, title) {
    this.map.setCenter(location);
    this.map.setZoom(12);

    var infowindow = new google.maps.InfoWindow({
        content: '<b>'+title+'</b>',
        size: new google.maps.Size(150,50)
      });

    if(!this.marker) {
      this.marker = new google.maps.Marker({
          position: location,
          map: this.map, 
          title: title,
      });
    } else {
      this.marker.setPosition(location);
    }
  }

  openErrorModal(msg:string) {
    this.ngZone.run(() => {
      const modalRef = this.modalService.open(ErrorModalComponent);
      modalRef.componentInstance.errorMessage = msg;
    });
  }

}
