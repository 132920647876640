import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { Subject } from 'rxjs';
import { DeliveriesDataSource, DeliveryService } from 'src/app/services/delivery.service';
import { NgbdSortableHeader, SortEvent } from '../../directives/sortable-directive';
import { Delivery } from 'src/app/models/delivery.model';
import { NgbCalendar, NgbDateParserFormatter, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Component({
  selector: 'app-deliveries',
  templateUrl: './deliveries.component.html',
  styleUrls: ['./deliveries.component.scss']
})
export class DeliveriesComponent implements OnInit {
  dataSource: DeliveriesDataSource;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  hoveredDate: NgbDate | null = null;

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(private router: Router, private calendar: NgbCalendar, public formatter: NgbDateParserFormatter, private deliveryService: DeliveryService) {
    this.dataSource = new DeliveriesDataSource(this.deliveryService);

    this.dataSource.load().subscribe(
      (deliveries) => {
        deliveries.forEach((d) => {
          d.datetime = new Date(d.datetime)
        });
      }
    );

    //this.fromDate = calendar.getNext(calendar.getToday(), 'd', -2);
    //this.fromDate = calendar.getToday();
    //this.toDate = calendar.getToday();
  }

  ngOnInit() {
  }

  /* range selection - non permette di selezionare il singolo giorno
  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }*/

  onSort({column, direction}: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.dataSource.sortColumn = column;
    this.dataSource.sortDirection = direction;
  }

  public formatDate(date:Date) {
    return moment(date).format('DD/MM/YYYY, HH:mm:ss');
  }

  public edit(event, delivery) {
    this.router.navigate(['delivery-details/' + delivery.id], {state: {delivery: delivery}});
  }

  public getStatusIcon(delivery:Delivery) {
    return delivery.status === Delivery.STATUS_COMPLETED ? 'check' : 'paper-plane';
  }

  public getStatusTooltipMessage(delivery:Delivery) {
    return delivery.status === Delivery.STATUS_COMPLETED ? 'Delivered' : 'Not delivered';
  }
}
