import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject, of } from 'rxjs';
import { retry, catchError, finalize, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CollectionViewer, DataSource } from "@angular/cdk/collections";

import { AuthService } from './auth.service';
import { Vehicle } from 'src/app/models/vehicle.model';
import { PagedResult } from '../models/paged_result.model';
import { SearchInfo } from './interfaces/search-info.interface';
import { TableDataSource } from './table-datasource';
import { Service } from './base';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class VehicleService extends Service {
    constructor(protected router: Router, private http: HttpClient, protected authService:AuthService) {
        super(router, authService);
    }

    public get(data:SearchInfo, driverId: number = null): Observable<any> {
        if(data.ids && data.ids.length === 1) {
            return this.http.get<PagedResult<Vehicle>>(
                environment.customer_api_url + 'vehicle/' + data.ids[0],
                {}
            );
        }

        let params:any = {};
        if(data.search)
            params.search = data.search;

        if(driverId)
            params.driverId = driverId;

        return this.http.get<any>(
            environment.customer_api_url + `vehicles`,
            {
                params: params,
            }
        );
    }

    public add(vehicle:Vehicle): Observable<any> {
        const options = {
            responseType: 'text' as 'json',
        };

        
        return this.http.post<string>(environment.customer_api_url + `vehicle`, vehicle, options)
            .pipe(map((res) => { return JSON.parse(res); }));
    }

    public update(vehicle:Vehicle): Observable<any> {
        const options = {
            responseType: 'text' as 'json',
        };

        return this.http.put<string>(environment.customer_api_url + 'vehicle/' + vehicle.id, vehicle, options);
    }
}

export class VehiclesDataSource {
    public loading = false;

    constructor(private vehicleService: VehicleService) {}

    read(data:SearchInfo, driverId: number = null): Observable<any> {
        this.loading = true;

        return this.vehicleService.get(data, driverId).pipe(
            catchError((err) => {
              return throwError(err);
            }),
            finalize(() => this.loading = false)
        );
    }    
}

export class VehiclesTableDataSource extends TableDataSource<Vehicle> {
    constructor(private vehicleService: VehicleService) {
        super(vehicleService);
    }
}