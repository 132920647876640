export class Tenant {
    static STATUS_ACTIVE = "A";
    static STATUS_DISABLED = "D";
    static STATUS_LIST = ['A', 'D'];

    static TYPE_LIGHT = 'L';
    static TYPE_FULL = 'F';
    static TYPE_LIST = ['L', 'F'];

    public id:string = null;
    public name:string = null;
    public status:string = null;
    public type:string = null;
}