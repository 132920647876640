import { Component, OnInit, NgZone } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Customer } from 'src/app/models/customer.model';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomerService } from 'src/app/services/customer.service';
import { ErrorModalComponent } from 'src/app/components/error-modal/error-modal.component';
import { GpsLocation } from 'src/app/models/gps_location';
import { SearchInfo } from 'src/app/services/interfaces/search-info.interface';

declare const google: any;

@Component({
  selector: 'app-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.css']
})
export class CustomerDetailsComponent implements OnInit {
  customerForm: FormGroup;
  customer:Customer = null;
  viewState:any = null;

  canSave = true;
  loading = false;
  submitted = false;

  map = null;
  marker = null;

  constructor(private ngZone: NgZone, private router: Router, private readonly route: ActivatedRoute, private formBuilder: FormBuilder, private modalService: NgbModal, private customerService: CustomerService) {
    if(this.router.getCurrentNavigation().extras && this.router.getCurrentNavigation().extras.state) {
      this.viewState = this.router.getCurrentNavigation().extras.state;
      this.canSave = false;
    } else {
      this.route.paramMap.subscribe(r => {
        let id = r.get("id");

        if(id) {
          this.loading = true;
          let s = new SearchInfo([id]);
          customerService.get(s).subscribe(
            (customer) => {
              this.setData(customer);
              this.updateMap(this.customer.gps_location.latitude, this.customer.gps_location.longitude);
            }
          );
        }
      });  
    }
  }

  get f() { return this.customerForm.controls; }

  ngOnInit(): void {
    this.customerForm = this.formBuilder.group({
      name: new FormControl('', [Validators.required]),
      address: new FormControl('', Validators.required),
      latitude: new FormControl('', [Validators.required, Validators.pattern('[0-9,\.]+')]),
      longitude: new FormControl('', [Validators.required, Validators.pattern('[0-9,\.]+')]),
    });

    if(this.viewState) {
      this.setData(this.viewState.customer);
    }

    this.initMap();
  }

  setData(customer:Customer) {
    this.customer = customer;

    this.f.name.setValue(this.customer.name);
    this.f.address.setValue(this.customer.address);
    this.f.latitude.setValue(this.customer.gps_location.latitude);
    this.f.longitude.setValue(this.customer.gps_location.longitude);

    this.canSave = true;
    this.loading = false;
  }    

  async onSubmit() {
    this.loading = true;
    this.submitted = true;

    if(!this.validate()) {
      this.loading = false;
      return;
    }

    if(!this.customer)
      this.customer = new Customer();

      this.customer.name = this.f.name.value;
      this.customer.address = this.f.address.value;
      this.customer.status = Customer.STATUS_ACTIVE;

      let latitude = typeof this.f.latitude.value === 'string' ? parseFloat(this.f.latitude.value.replace(',', '.')) : this.f.latitude.value;
      let longitude = typeof this.f.longitude.value === 'string' ? parseFloat(this.f.longitude.value.replace(',', '.')) : this.f.longitude.value;
      this.customer.gps_location = new GpsLocation(latitude, longitude);

      let promise = null;
      if(this.customer.id && this.customer.id !== '_auto_') {
        promise = this.customerService.update(this.customer).toPromise();
      } else {
        this.customer.id = '_auto_';
        promise = this.customerService.add(this.customer).toPromise();
        let self = this;
        promise.then((res) => {
          self.customer.id = res.id;
        });
      }

      promise
        .then((r) => { this.loading = false; })
        .catch((err) => { 
          this.loading = false;
          console.log('Error:', err);
          this.openErrorModal(err.error);
        });
  }

  validate() {
    if (this.customerForm.invalid) {
      return;
    }

    return true;
  }

  initMap(lat = null, lng = null, zoom=12) {
    let map_canvas = document.getElementById('map-canvas');

    if(!lat && !lng && this.customer) {
      lat = this.customer.gps_location.latitude;
      lng = this.customer.gps_location.longitude;
    }

    if(!lat || !lng) {
      lat = 41.873604;
      lng = 12.463417;
      zoom = 5;
    }

    var myLatlng = new google.maps.LatLng(lat, lng);
    var mapOptions = {
        zoom: zoom,
        scrollwheel: true,
        center: myLatlng,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        styles: [
          {"featureType":"administrative","elementType":"labels.text.fill","stylers":[{"color":"#444444"}]},
          {"featureType":"landscape","elementType":"all","stylers":[{"color":"#f2f2f2"}]},
          {"featureType":"poi","elementType":"all","stylers":[{"visibility":"off"}]},
          {"featureType":"road","elementType":"all","stylers":[{"saturation":-100},{"lightness":45}]},
          {"featureType":"road.highway","elementType":"all","stylers":[{"visibility":"simplified"}]},
          {"featureType":"road.arterial","elementType":"labels.icon","stylers":[{"visibility":"off"}]},
          {"featureType":"transit","elementType":"all","stylers":[{"visibility":"off"}]},
          {"featureType":"water","elementType":"all","stylers":[{"color":'#5e72e4'},{"visibility":"on"}]}
        ]
    }

    this.map = new google.maps.Map(map_canvas, mapOptions);

    // Create the search box and link it to the UI element.
    var input = document.getElementById('pac-input');
    var searchBox = new google.maps.places.SearchBox(input);
    this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);

    // Bias the SearchBox results towards current map's viewport.
    this.map.addListener('bounds_changed', function() {
      searchBox.setBounds(this.map.getBounds());
    }.bind(this));

    var infowindow = new google.maps.InfoWindow({
        content: ''
    });

    /*
    google.maps.event.addListener(marker, 'click', function() {
        infowindow.open(map, marker);
    });
    */

    this.findInMap(null);
  }

  updateMap(lat = null, lng = null, zoom=12) {
    var myLatlng = new google.maps.LatLng(lat, lng);

    this.map.setCenter(myLatlng);
    this.map.setZoom(zoom);

    this.setMarker(myLatlng, lat + ' ' + lng);
  }

  public findInMap(event, context:string = null) {
    let address = this.f.address.value;
    let latitude = this.f.latitude.value;
    let longitude = this.f.longitude.value;

    if(!context && (!latitude || !longitude))
      context = 'address';

    if(context === 'address') {
      if(!address)
        return;

      let geocoder = new google.maps.Geocoder();
      if (geocoder) {
        geocoder.geocode( { 'address': address}, function(results, status) {
          if (status == google.maps.GeocoderStatus.OK) {
            if (status != google.maps.GeocoderStatus.ZERO_RESULTS) {
              location = results[0].geometry.location;
              this.setMarker(location, address);

              if(!this.f.latitude.value || !this.f.longitude.value) {
                this.f.latitude.setValue(location.lat());
                this.f.longitude.setValue(location.lng());
              }

            } else {
              alert("No results found");
            }
          } else {
            alert("Geocode was not successful for the following reason: " + status);
          }
        }.bind(this));
      }
    } else {
      if(!latitude || !longitude)
        return;

      var location = new google.maps.LatLng(latitude, longitude);
      this.setMarker(location, latitude + ' ' + longitude);
    }
  }

  private setMarker(location, title) {
    this.map.setCenter(location);
    this.map.setZoom(12);

    var infowindow = new google.maps.InfoWindow({
        content: '<b>'+title+'</b>',
        size: new google.maps.Size(150,50)
      });

    if(!this.marker) {
      this.marker = new google.maps.Marker({
          position: location,
          map: this.map, 
          title: title,
          draggable: true,
      });
      google.maps.event.addListener(this.marker, 'click', function() {
          infowindow.open(this.map, this.marker);
      });
    } else {
      this.marker.setPosition(location);
    }
  }

  public getMarkerLocation() {
    let position = this.marker.getPosition();

    this.f.latitude.setValue(position.lat());
    this.f.longitude.setValue(position.lng());
  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.customerForm.controls;
    for (const name in controls) {
        if (controls[name].invalid) {
            invalid.push(name);
        }
    }

    return invalid;
  }

  openErrorModal(msg:string) {
    this.ngZone.run(() => {
      const modalRef = this.modalService.open(ErrorModalComponent);
      modalRef.componentInstance.errorMessage = msg;
    });
  }

}
