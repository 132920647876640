import { GpsLocation } from "./gps_location";

export class Customer {
    static STATUS_ACTIVE = 'A';
    static STATUS_DISABLED = 'D';
    static STATUS_LIST = ['A', 'D'];

    public id:string = null;
    public tenant_id:string = null;
    public name:string = null;
    public address:string = null;
    public status:string = null;
    public gps_location:GpsLocation = null;
}