export class SearchInfo {
    public constructor(ids:string[] = null, pageSize:number = 40, page:number = 0, search:string = null, sortColumn:string = null, sortDirection:string = null) {
        this.ids = ids;
        this.pageSize = pageSize;
        this.page = page;
        this.search = search;
        this.sortColumn = sortColumn;
        this.sortDirection = sortDirection;
    }

    ids:string[] = null;
    pageSize:number = 0;
    page:number = 0;
    search:string = null;
    sortColumn:string = null;
    sortDirection:string = null;
}

export class SearchInfoDeliveries extends SearchInfo {

    public constructor(ids:string[] = null, pageSize:number = 40, page:number = 0, search:string = null, sortColumn:string = null, sortDirection:string = null, codes: string[] = null) {
        super(ids, pageSize,page,search, sortColumn, sortDirection);
        this.codes = codes;
    }

    codes: string[] = null;
}