import { Component, OnInit, NgZone } from '@angular/core';
import { AuthService } from 'src/app/services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  constructor(private ngZone:NgZone, private router: Router, private authService:AuthService) { }

  ngOnInit(): void {
    this.authService.logout();
    //this.router.navigate(['/login']);
    let self = this;
    this.ngZone.run(() => self.router.navigate(['/login']));
  }

}
