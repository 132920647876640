import { Component, OnInit, NgZone } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Tenant } from 'src/app/models/tenant.model';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TenantService } from 'src/app/services/tenant.service';
import { ErrorModalComponent } from 'src/app/components/error-modal/error-modal.component';
import { SearchInfo } from 'src/app/services/interfaces/search-info.interface';

@Component({
  selector: 'app-tenant-details',
  templateUrl: './tenant-details.component.html',
  styleUrls: ['./tenant-details.component.css']
})
export class TenantDetailsComponent implements OnInit {
  tenantForm: FormGroup;
  canSave = true;
  loading = false;
  tenant:Tenant = null;
  viewState:any = null;

  submitted = false;

  constructor(private ngZone: NgZone, private router: Router, private readonly route: ActivatedRoute, private formBuilder: FormBuilder, private modalService: NgbModal, private tenantService: TenantService) {
    if(this.router.getCurrentNavigation().extras && this.router.getCurrentNavigation().extras.state) {
      this.viewState = this.router.getCurrentNavigation().extras.state;
      this.canSave = false;
      this.loading = false;
    } else {
      this.route.paramMap.subscribe(r => {
        let id = r.get("id");

        if(id) {
          this.loading = true;
          let s = new SearchInfo([id]);
          tenantService.get(s).subscribe(
            (tenant) => {
              this.setData(tenant);
            }
          );
        }
      });  
    }
  }

  get f() { return this.tenantForm.controls; }

  ngOnInit(): void {
    this.tenantForm = this.formBuilder.group({
      name: new FormControl('', Validators.required),
      status: new FormControl('', Validators.required),
      type: new FormControl('', Validators.required),
    });

    if(this.viewState) {
      this.setData(this.viewState.tenant);
    }
  }

  setData(tenant:Tenant) {
    this.tenant = tenant;

    this.f.name.setValue(this.tenant.name);
    this.f.status.setValue(this.tenant.status);
    this.f.type.setValue(this.tenant.type);

    this.canSave = true;
    this.loading = false;
  }

  async onSubmit() {
    this.loading = true;
    this.submitted = true;

    if(!this.validate()) {
      this.loading = false;
      return;
    }

    if(!this.tenant)
      this.tenant = new Tenant();

    this.tenant.name = this.f.name.value;
    this.tenant.status = this.f.status.value;
    this.tenant.type = this.f.type.value;

    let promise = null;
    if(this.tenant.id && this.tenant.id !== '_auto_') {
      promise = this.tenantService.update(this.tenant).toPromise();
    } else {
      this.tenant.id = '_auto_';
      promise = this.tenantService.add(this.tenant).toPromise();
      let self = this;
      promise.then((res) => {
        self.tenant.id = res.id;
      });
    }

    promise
      .then((r) => { this.loading = false; })
      .catch((err) => { 
        this.loading = false;
        console.log('Error:', err);
        this.openErrorModal(err.error);
      });
  }

  validate() {
    if (this.tenantForm.invalid) {
      return;
    }

    return true;
  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.tenantForm.controls;
    for (const name in controls) {
        if (controls[name].invalid) {
            invalid.push(name);
        }
    }

    return invalid;
  }

  openErrorModal(msg:string) {
    this.ngZone.run(() => {
      const modalRef = this.modalService.open(ErrorModalComponent);
      modalRef.componentInstance.errorMessage = msg;
    });
  }

}
