import { BehaviorSubject, Subject, Observable } from "rxjs";
import { SearchInfo } from "./interfaces/search-info.interface";
import { tap, debounceTime, switchMap, delay } from "rxjs/operators";
import { SortDirection } from "../directives/sortable-directive";
import { PagedResult } from "../models/paged_result.model";


export class TableDataSource<T> {
    private _loading$ = new BehaviorSubject<boolean>(true);
    private _items$ = new BehaviorSubject<T[]>([]);
    private _read$ = new Subject<void>();
    private _total$ = new BehaviorSubject<number>(0);
    private _serviceArgs = null;

    private service = null;

    protected searchInfo: SearchInfo = {
        ids: null,
        page: 1,
        pageSize: 4,
        search: '',
        sortColumn: '',
        sortDirection: ''
    };

    constructor(service, serviceArgs = null) {
        this.service = service;

        if(serviceArgs)
            this._serviceArgs = serviceArgs;
    }

    load(): BehaviorSubject<T[]> {
        this._read$.pipe(
            tap(() => this._loading$.next(true)),
            debounceTime(200),
            switchMap(() => this._search()),
            delay(200),
            tap(() => this._loading$.next(false))
          ).subscribe(result => {
            this._items$.next(result.items);
            //this._total$.next(result.total);
            this._total$.next(0);
          }, (err) => {
              console.log(err);
            this._loading$.next(false);
          });
      
          this._read$.next();

          return this._items$;
    }

    get items$() { return this._items$.asObservable(); }
    get total$() { return this._total$.asObservable(); }
    get loading$() { return this._loading$.asObservable(); }
    get page() { return this.searchInfo.page; }
    get pageSize() { return this.searchInfo.pageSize; }
    get search() { return this.searchInfo.search; }

    set page(page: number) { this._triggerSearch({page}); }
    set pageSize(pageSize: number) { this._triggerSearch({pageSize}); }
    set search(search: string) { this._triggerSearch({search}); }
    //set sortColumn(sortColumn: SortColumn) { this._set({sortColumn}); }
    set sortColumn(sortColumn: string) { this._triggerSearch({sortColumn}); }
    set sortDirection(sortDirection: SortDirection) { this._triggerSearch({sortDirection}); }

    private _triggerSearch(patch: Partial<SearchInfo> = null) {
        if(patch)
            Object.assign(this.searchInfo, patch);

        this._read$.next();
    }

    public triggerSearch(serviceArgs = null) {
        if(serviceArgs)
            this._serviceArgs = serviceArgs;

        this._triggerSearch();
    }

    private _search(): Observable<PagedResult<T>> {
        const {sortColumn, sortDirection, pageSize, page, search} = this.searchInfo;
        
        return this.read(pageSize, page, search, sortColumn, sortDirection);
    }

    protected read(pageSize, page, search, sortColumn, sortDirection): Observable<PagedResult<T>> {
        return this.service.get(new SearchInfo(null, pageSize, page, search, sortColumn, sortDirection), this._serviceArgs);
    }
}