import { Component, OnInit, NgZone } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { CU } from 'src/app/models/cu.model';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CUService } from 'src/app/services/cu.service';
import { ErrorModalComponent } from 'src/app/components/error-modal/error-modal.component';
import { SearchInfo, SearchInfoDeliveries } from 'src/app/services/interfaces/search-info.interface';
import { AuthService } from 'src/app/services';
import { TenantsDataSource, TenantService } from 'src/app/services/tenant.service';
import { Observable, Subject, merge, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, map, catchError } from 'rxjs/operators';

@Component({
  selector: 'app-cu-details',
  templateUrl: './cu-details.component.html',
  styleUrls: ['./cu-details.component.css']
})
export class CUDetailsComponent implements OnInit {
  cuForm: FormGroup;
  canSave = true;
  loading = false;
  cu: CU = null;
  viewState: any = null;

  submitted = false;

  tenantsDataSource: TenantsDataSource;
  tenants = null;

  vendorTenantsDataSource: TenantsDataSource;
  vendorTenants = null;

  focusTenants$ = new Subject<string>();
  focusVendorTenants$ = new Subject<string>();

  tenantsTest = null;

  constructor(private router: Router, private readonly route: ActivatedRoute, private ngZone: NgZone,
    private formBuilder: FormBuilder, private modalService: NgbModal, private cuService: CUService,
    public authService: AuthService, private tenantService: TenantService) {
    if (this.router.getCurrentNavigation().extras && this.router.getCurrentNavigation().extras.state) {
      this.viewState = this.router.getCurrentNavigation().extras.state;
      this.canSave = false;
      this.loading = false;
    } else {
      this.route.paramMap.subscribe(r => {
        let id = r.get("id");

        if (id) {
          this.loading = true;
          let s = new SearchInfoDeliveries([id]);
          cuService.get(s).subscribe(
            (cu) => {
              this.setData(cu);
            }
          );
        }
      });
    }

    this.tenantsDataSource = new TenantsDataSource(this.tenantService);


    /*    this.tenantsDataSource.read(new SearchInfo()).toPromise()
        .then((res) => {
          this.tenants = res.items;
        }) */

    this.tenants = (text$: Observable<any>) => {
      const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
      const inputFocus$ = this.focusTenants$;

      return merge(debouncedText$, inputFocus$).pipe(
        switchMap((search: string) =>
          this.tenantsDataSource.read(new SearchInfo(null, 10, 0, search)).pipe(
            map((response) => {
              return response.items;
            }),
            catchError(() => {
              return of([]);
            })
          )
        )
      );
    }


  this.vendorTenantsDataSource = new TenantsDataSource(this.tenantService);

    this.vendorTenants = (text$: Observable<any>) => {
      const debouncedText$ = text$.pipe(
        debounceTime(300),
        distinctUntilChanged());

      const inputFocus$ = this.focusVendorTenants$;


      return merge(debouncedText$, inputFocus$).pipe(
        switchMap(search =>
          this.vendorTenantsDataSource.read(new SearchInfo(null, 10, 0, search)).pipe(
            map((response) => {
              //console.log(response, this.cuForm.get('tenant').value.subtenants.filter);
              
              return response.items;
            }),
            catchError(() => {
              return of([]);
            })
          )
        )
      );
    }
}

  get f() { return this.cuForm.controls; }

  tasksToWait = 2;
  tasksCompleted = 0;
  ngOnInit(): void {
    this.cuForm = this.formBuilder.group({
      code: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
      status: new FormControl(CU.STATUS_ACTIVE, Validators.required),
      tenant: new FormControl(''),
      vendor_tenant: new FormControl(''),
    });

    if (this.viewState) {
      this.setData(this.viewState.cu);
    }
  }

  setData(cu: CU) {
    this.tasksCompleted = 0;

    this.cu = cu;

    this.f.code.setValue(this.cu.code);
    this.f.description.setValue(this.cu.description);
    this.f.status.setValue(this.cu.status);

    if (this.cu.tenant_id) {
      this.tenantsDataSource.read(new SearchInfo([this.cu.tenant_id])).toPromise()
        .then((res) => {
          this.f.tenant.setValue(res);

          if (++this.tasksCompleted == this.tasksToWait) this.canSave = true;
        });
    } else {
      this.tasksCompleted++;
    }

    if (this.cu.vendor_tenant_id) {
      this.vendorTenantsDataSource.read(new SearchInfo([this.cu.vendor_tenant_id])).toPromise()
        .then((res) => {
          this.f.vendor_tenant.setValue(res);

          if (++this.tasksCompleted == this.tasksToWait) this.canSave = true;
        });
    } else {
      this.tasksCompleted++;
    }

    if (this.tasksCompleted == this.tasksToWait)
      this.canSave = true;

    this.loading = false;
  }

  async onSubmit() {
    this.loading = true;
    this.submitted = true;

    if (!this.validate()) {
      this.loading = false;
      return;
    }

    if (!this.cu)
      this.cu = new CU();

    this.cu.code = this.f.code.value;
    this.cu.description = this.f.description.value;
    this.cu.status = this.f.status.value;
    this.cu.tenant_id = this.f.tenant.value ? this.f.tenant.value.id : undefined;
    this.cu.vendor_tenant_id = this.f.vendor_tenant ? this.f.vendor_tenant.value.id : undefined;

    let promise = null;
    if (this.cu.id && this.cu.id !== '_auto_') {
      promise = this.cuService.update(this.cu).toPromise();
   
    } else {
      let self = this;
      this.cu.id = '_auto_';
      promise = this.cuService.add(this.cu).toPromise();
      promise
        .then((res) => {
          self.cu.id = res.id;
        })
        .catch((err) => self.cu.id = null);
    }

    promise
      .then((r) => {
        this.loading = false;
        this.cu.id = this.cu.code;
      })
      .catch((err) => {
        this.loading = false;
        //this.cu.id = null
        this.openErrorModal(err.error);
        this.cu = null;
      });
  }


  tenantsAutocompleteResultFormat(value: any) {
    return value.name;
  }

  tenantsAutocompleteListItemFormat(value: any) {
    if (value.name)
      return value.name
    return value;
  }

  validate() {
    if (this.cuForm.invalid) {
      return;
    }

    return true;
  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.cuForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }

    return invalid;
  }

  openErrorModal(msg: string) {
    this.ngZone.run(() => {
      const modalRef = this.modalService.open(ErrorModalComponent);
      modalRef.componentInstance.errorMessage = msg;
    });
  }

}
