import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { Observable, from } from 'rxjs';

import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import decode from 'jwt-decode';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private router: Router, private authService: AuthService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return from(this.checkToken(request, next));
    }

    private async checkToken(request: HttpRequest<any>, next: HttpHandler) {
        if(request.url.endsWith('/login'))
            return next.handle(request).toPromise();

        if(!await this.authService.checkToken()) {
            this.router.navigate(['/login']);
            return next.handle(request).toPromise();
        }

        const authReq = request.clone({
            setHeaders: {
                'Authorization': 'bearer ' + this.authService.getToken(),
                'Cache-Control': "no-cache"
            }
        });
      
        return next.handle(authReq).toPromise()
    }
}