import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable, throwError } from 'rxjs';
import { retry, catchError, map, finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TableDataSource } from './table-datasource';
import { Router } from '@angular/router';
import { Service } from './base';
import { Tenant } from '../models/tenant.model';
import { SearchInfo } from './interfaces/search-info.interface';
import { PagedResult } from '../models/paged_result.model';

@Injectable({ providedIn: 'root' })
export class TenantService extends Service {
    constructor(protected router: Router, private http: HttpClient, protected authService:AuthService) {
        super(router, authService);
    }

    public get(data:SearchInfo): Observable<any> {
        if(data.ids && data.ids.length === 1) {
            return this.http.get<PagedResult<Tenant>>(
                environment.customer_api_url + 'tenant/' + data.ids[0],
                {}
            );
        }

        let params:any = {};
        if(data.search)
            params.search = data.search;

        return this.http.get<any>(
            environment.customer_api_url + `tenants`,
            {
                params: params,
            }
        );
    }

    public add(tenant:Tenant): Observable<any> {
        const options = {
            responseType: 'text' as 'json',
        };

        return this.http.post<string>(environment.customer_api_url + `tenant`, tenant, options)
            .pipe(map((res) => { return JSON.parse(res); }));
    }

    public update(tenant:Tenant): Observable<any> {
        const options = {
            responseType: 'text' as 'json',
        };

        return this.http.put<string>(environment.customer_api_url + 'tenant/' + tenant.id, tenant, options);
    }

    handleError(error) {
        let errorMessage = '';
        if(error.error instanceof ErrorEvent) {
          // Get client-side error
          errorMessage = error.error.message;
        } else {
          // Get server-side error
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        window.alert(errorMessage);
        return throwError(errorMessage);
     }
}

export class TenantsDataSource {
    public loading = false;

    constructor(private tenantService: TenantService) {}

    read(data:SearchInfo): Observable<any> {
        this.loading = true;

        return this.tenantService.get(data).pipe(
            catchError((err) => {
              return throwError(err);
            }),
            finalize(() => this.loading = false)
        );
    }    
}

export class TenantsTableDataSource extends TableDataSource<Tenant> {
    constructor(private tenantService: TenantService) {
        super(tenantService);
    }
}
