import { Smartlock } from "./smartlock.model";

export class Vehicle {
    static STATUS_ACTIVE = 'A';
    static STATUS_DISABLED = 'D';

    static STATUS_LIST = ['A', 'D'];

    public id:string = null;
    public tenant_id:string = null;
    public name:string = null;
    public status:string = null;
    public cu_id:string = null; //TODO Delete -----> multiple cus allowed
    public unlock_codes:Array<string> = [];
    public expirationDatetimeUnlockCodes: Date = null;
    public cu_ids: string[] = [];
    public smartlocks:Smartlock[] = [];
    public lastPositions?: { lat: string, lon: string }[] = []
    public numberUnlockCodes: number = null;
}

