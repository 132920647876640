import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class MyValidators {
    static isSelected(): AsyncValidatorFn {
        return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
            return new Promise(function(resolve, reject) {
                resolve(
                    typeof control.value === 'string' ?
                        <ValidationErrors>{
                            'is_selected': typeof control.value !== 'string'
                        } : 
                        null);
            });
        };
    }
}