import { GpsLocation } from "./gps_location";

export class Delivery {
    static STATUS_READY = 'R';
    static STATUS_COMPLETED = 'C';

    public id:string = null;
    public tenant_id:string = null;
    public gps_location:GpsLocation = null;
    public radius:number = null;
    public unlock_time:number = null;
    public status:string = null;
    public datetime:Date = null;
    public datetime_end:Date = null;
    public driver_id:string = null;
    public driver:Delivery_Driver;
    public vehicle_id:string = null;
    public vehicle:Delivery_Vehicle;
    public customer_id:string = null;
    public customer:Delivery_Customer;
    public data: { [id: string] : any; } = {};
    public encrypted_data:string = null;
    public unlock_codes:string[] = [];
    public smartlocks_positions: Array<string>;

    // Delivered
    public delivered_datetime:Date = null;
    public delivered_gps_location:GpsLocation = null;
}

export class Delivery_Driver {
    public id:string = null;
    public name:string = null;
}

export class Delivery_Vehicle {
    public id:string = null;
    public name:string = null;
    public unlock_codes:string[] = [];
    public smartlocks:Smartlock[];
}

export class Smartlock {
    public id:string = null;
    public position:string = null;
}

export class Delivery_Customer {
    public id:string = null;
    public name:string = null;
}