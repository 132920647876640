import { AuthService } from "./auth.service";
import { Router } from "@angular/router";
import { from } from "rxjs";

export class Service {
    constructor(protected router: Router, protected authService:AuthService) {

    }
    
    public checkToken() {
        const observable = from(this.authService.checkToken());

        this.router.navigate(['/login']);
        return false;
    }
}